import UserService from './UserService';
import LocationService from '../Location/LocationService';
import AuthenticationState from '../Entity/Authentication/AuthenticationState';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import User from '../Entity/User/User';
import Country from '../Entity/Location/Country';
import FormData from '../Entity/Form/FormData';
import SelectOption from '../Entity/Form/SelectOption';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import UserBaseDataForm from './UserBaseDataForm';
import UserEmailChangeForm from './UserEmailChangeForm';
import UserPasswordChangeForm from './UserPasswordChangeForm';
import Divider from '../Component/Divider/Divider';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import InfoBox from '../Component/InfoBox/InfoBox';
import AlertBox from '../../../components/AlertBox';
import Spinner from '../../../components/Spinner';
import {useAppDispatch, useAppSelector} from '../../../app/hooks';
import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

const successAlert: Alert = new Alert(AlertType.Success, 'Die Stammdaten wurden erfolgreich aktualisiert.');

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Die Stammdaten konnten nicht aktualisiert werden, bitte fülle alle rot markierten Pflichtfelder aus.');

const userBaseDataFieldValidationDefinitions: FieldValidationDefinition<User>[] = [
    new RequiredValidationDefinition<User>('firstName', 'Vorname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<User>('lastName', 'Nachname muss ausgefüllt sein.'),
    new RequiredValidationDefinition<User>('streetName', 'Straße muss ausgefüllt sein.'),
    new RequiredValidationDefinition<User>('houseNumber', 'Hausnummer. muss ausgefüllt sein.'),
    new RequiredValidationDefinition<User>('postalCode', 'PLZ muss ausgefüllt sein.'),
    new RequiredValidationDefinition<User>('placeName', 'Ort muss ausgefüllt sein.'),
    new RequiredValidationDefinition<User>('country', 'Ein Land muss gewählt sein.'),
];

const formValidationHandlerUserBaseData: FormValidationHandler<User> = new FormValidationHandler<User>(userBaseDataFieldValidationDefinitions);

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const locationService: LocationService = new LocationService(process.env.REACT_APP_LLASM_API_URL!);

const UserProfilePage = (): React.JSX.Element => {
    const {authenticatedUser}: AuthenticationState = useAppSelector<AuthenticationState>(state => state.authentication);

    const [user, setUser] = useState<User>();

    const [countrySelectOptions, setCountrySelectOptions] = useState<SelectOption<Country>[]>();

    const [formData, setFormData] = useState<FormData<User>>({data: user!});

    const [isLoading, setIsLoading] = useState(false);

    const [alert, setAlert] = useState<Alert>();

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Meine Stammdaten'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchData();
    }, []);

    const fetchData = async (): Promise<void> => {
        try {
            await fetchUser();
            await fetchCountrySelectOptions();
        } catch (error) {
            throw error;
        }
    };

    const fetchCountrySelectOptions = async (): Promise<void> => {
        const countrySelectOptions: SelectOption<Country>[] = (await (locationService.fetchCountries())).map((country: Country): SelectOption<Country> => {
            return {
                label: country.countryName,
                value: country
            };
        });

        setCountrySelectOptions(countrySelectOptions);
    };

    const fetchUser = async (): Promise<void> => {
        const user: User = await userService.fetchUserFromApiById(authenticatedUser?.user.id!);

        setUser(user);
        setFormData({...formData, data: user});
    };

    const updateUserBaseData = async (): Promise<void> => {
        formValidationHandlerUserBaseData.validate(formData);

        if (formValidationHandlerUserBaseData.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setIsLoading(true);

        try {
            await userService.updateUser(formData.data);

            const user: User = await userService.fetchUserFromApiById(authenticatedUser?.user.id!);

            setUser(user);

            setAlert(successAlert);
        } catch (error) {
            setAlert(errorAlert);
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    if (user === undefined || countrySelectOptions === undefined || isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-3">
                <div className="col-12 col-md-8 d-flex align-items-center">
                    <Link to="/"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Meine Stammdaten</h1>
                </div>
            </div>
            <InfoBox cardType="outline" className="p-3 mx-3 mb-3">
                <div className="fs-5 mb-0">
                    Hier kannst Du Deine Stammdaten und Deine Zugangsdaten ändern. Bitte fülle alle Felder aus.
                    {(user.naturalPerson === false) ? (
                        <span> Sollte sich Dein Firmenname geändert haben oder Du Deinen Account löschen wollen, so kontaktiere bitte David unter <a className="text-white" href="mailto:help@llasm.de">help@llasm.de</a> / +49 2171 40188-46.</span>
                    ) : (
                        <span> Wenn Du Deinen Account löschen willst, so kontaktiere bitte David unter <a className="text-white" href="mailto:help@llasm.de">help@llasm.de</a> / +49 2171 40188-46.</span>
                    )}
                    <span> Bitte beachte, dass Deine angegebenen Daten auch gleichzeitig die Rechnungsadresse für den Kauf von Matchpoints ist.</span>
                </div>
            </InfoBox>
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }
            <div className="mb-3 px-md-3">
                <CollapseCard cardType="shadow" title="Stammdaten" className="mb-3">
                    <UserBaseDataForm
                        formData={formData}
                        setFormData={setFormData}
                        countrySelectOptions={countrySelectOptions}
                        formValidationHandler={formValidationHandlerUserBaseData}
                    />
                    <button type="submit" className="btn btn-primary d-flex align-items-center mt-3 mt-3" onClick={updateUserBaseData}>SPEICHERN</button>
                </CollapseCard>
            </div>
            <div className="p-3 mb-3">
                <CollapseCard cardType="shadow" title="Zugangsdaten" className="mb-3">
                    <div className="mb-4">
                        Hier hast Du die Möglichkeit, Deine E-Mail-Adresse und Dein Passwort zu ändern.
                        Für das Ändern Deiner E-Mail-Adresse musst Du einen Validierungsprozess durchlaufen.
                        Hierzu senden wir Dir eine Nachricht an Deine neue Adresse.
                    </div>
                    <UserEmailChangeForm user={user} />
                    <Divider color="lightgrey" />
                    <UserPasswordChangeForm />
                </CollapseCard>
            </div>
        </div>
    );
};

export default UserProfilePage;
