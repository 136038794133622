import MarketplaceLotPurchase from '../../Entity/Marketplace/MarketplaceLotPurchase';
import MarketplaceLotDuration, {getMarketplaceLotDurationAmountOfDays, getMarketplaceLotDurationBaseCost} from '../../Entity/Marketplace/MarketplaceLotDuration';
import MarketplaceLotPurchasePostRequest from '../../../../api/Llasm/Marketplace/MarketplaceLotPurchasePostRequest';
import LlasmApiV1Provider from '../../../../api/LlasmApiV1Provider';
import {AxiosResponse} from 'axios';

class MarketplaceLotPurchaseService {
    private readonly llasmApiV1Provider: LlasmApiV1Provider;

    public constructor(apiUrl: string) {
        this.llasmApiV1Provider = new LlasmApiV1Provider(apiUrl);
    }

    public async purchaseMarketplaceLots(marketplaceLotPurchase: MarketplaceLotPurchase): Promise<void> {
        const apiResponse: AxiosResponse<null> = await this.llasmApiV1Provider.post<null, AxiosResponse<null>, MarketplaceLotPurchasePostRequest>(
            '/marketplace-lots/purchase',
            MarketplaceLotPurchaseService.mapFromMarketplaceLotPurchaseToMarketplaceLotPurchasePostRequest(marketplaceLotPurchase)
        );
    }

    public static calculateAmountOfMatchPoints(
        marketplaceLotDuration: MarketplaceLotDuration,
        amountOfMarketplaceLots: number,
        minAmountOfMarketplaceLots: number,
        maxAmountOfMarketplaceLots: number,
        possibleMarketplaceLotDurations: MarketplaceLotDuration[]
    ): number {
        const validMarketplaceLotDuration: MarketplaceLotDuration | undefined = possibleMarketplaceLotDurations.find((possibleMarketplaceLotDuration: MarketplaceLotDuration): boolean => {
            return possibleMarketplaceLotDuration === marketplaceLotDuration;
        });

        if (validMarketplaceLotDuration === undefined) {
            throw new Error('Invalid duration for marketplace lots.');
        }

        if (amountOfMarketplaceLots < minAmountOfMarketplaceLots || amountOfMarketplaceLots > maxAmountOfMarketplaceLots) {
            throw new Error('Invalid amount of marketplace lots.');
        }

        if (amountOfMarketplaceLots === 1) {
            return getMarketplaceLotDurationBaseCost(marketplaceLotDuration);
        }

        const amountOfMatchPoints: number = 0.7 * (getMarketplaceLotDurationBaseCost(marketplaceLotDuration) * amountOfMarketplaceLots);

        return Math.ceil(amountOfMatchPoints);
    }

    private static mapFromMarketplaceLotPurchaseToMarketplaceLotPurchasePostRequest(marketplaceLotPurchase: MarketplaceLotPurchase): MarketplaceLotPurchasePostRequest {
        return {
            amountOfMarketplaceLots: marketplaceLotPurchase.amountOfMarketplaceLots,
            numberOfDays: getMarketplaceLotDurationAmountOfDays(marketplaceLotPurchase.marketplaceLotDuration),
            amountOfMatchPoints: marketplaceLotPurchase.amountOfMatchPoints
        };
    }
}

export default MarketplaceLotPurchaseService;
