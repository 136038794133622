import welcomeInfoBoxStyle from './WelcomeInfoBox.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';

interface WelcomeInfoBoxProps {
    readonly absolutePosition?: boolean;
}

const WelcomeInfoBox = (props: WelcomeInfoBoxProps): React.JSX.Element => {
    return (
        <div className={props.absolutePosition !== undefined && props.absolutePosition === true ? ['d-none', 'd-lg-block', 'p-3', 'col-lg-4', welcomeInfoBoxStyle.infoBox].join(' ') : 'p-2'}>
            <p>
                <strong>Schön, dass Du hier bist! Das kannst Du hier alles machen:</strong>
            </p>
            <p>
                Du hast leerstehende oder bald leerfallende Ladenflächen in Deiner Stadt und suchst dafür Mieter oder Käufer, die Deine Stadt bereichern? Dann bist Du hier genau richtig. Nutze unser Matching und finde Deinen neuen Nachnutzer!
            </p>
            <p>
                Du hast Fragen? Unser <Link to="https://www.llasm.de/kontakt" target="_blank">Support</Link> hilft Dir gerne weiter.
            </p>
            <p>
                <strong>Mach mit, match mit!</strong>
            </p>
        </div>
    );
};

export default WelcomeInfoBox;
