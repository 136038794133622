import MarketplaceService from '../MarketplaceService';
import MessageEntity from '../../Entity/Marketplace/Message';
import Alert from '../../Entity/Alert/Alert';
import FormData from '../../Entity/Form/FormData';
import AlertType from '../../Entity/Alert/AlertType';
import MarketplaceAd from '../../Entity/Marketplace/MarketplaceAd';
import MessageForm from './MessageForm';
import FieldValidationDefinition from '../../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../../FormValidationHandler/RequiredValidationDefinition';
import MinMaxLengthValidationDefinition from '../../FormValidationHandler/MinMaxLengthValidationDefinition';
import FormValidationHandler from '../../FormValidationHandler/FormValidationHandler';
import AlertBox from '../../../../components/AlertBox';
import React, {useState} from 'react';

interface MessageProps {
    readonly marketplaceAd: MarketplaceAd;
}

const marketplaceService: MarketplaceService = new MarketplaceService(process.env.REACT_APP_LLASM_API_URL!);

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Deine Nachricht konnte leider nicht gesendet werden. Bitte gib eine Nachricht mit mindestens 5 Zeichen ein.');
const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schief gelaufen. Bitte versuche es zu einem späteren Zeitpunkt noch einmal.');
const successAlert: Alert = new Alert(AlertType.Success, 'Deine Nachricht wurde erfolgreich übermittelt.');

const messageFieldValidationDefinitions: FieldValidationDefinition<MessageEntity>[] = [
    new RequiredValidationDefinition<MessageEntity>('text', 'Die Nachricht muss ausgefüllt sein.'),
    new MinMaxLengthValidationDefinition<MessageEntity>('text', 5, null, 'Die Nachricht muss mindestens 5 Zeichen lang sein.'),
];

const formValidationHandler: FormValidationHandler<MessageEntity> = new FormValidationHandler<MessageEntity>(messageFieldValidationDefinitions);

const Message = (props: MessageProps): React.JSX.Element => {
    const [alert, setAlert] = useState<Alert>();

    const [messageFormData, setMessageFormData] = useState<FormData<MessageEntity>>({data: new MessageEntity()});

    const [showMessageForm, setShowMessageForm] = useState<boolean>(true);

    const sendMessage = async (): Promise<void> => {
        formValidationHandler.validate(messageFormData);

        if (formValidationHandler.hasErrors(messageFormData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        try {
            await marketplaceService.sendMessage(props.marketplaceAd, messageFormData.data);

            setShowMessageForm(false);
            setAlert(successAlert);
        } catch (error) {
            setAlert(errorAlert);
        }
    };

    return (
        <div className="row">
            <div className="col-12 col-lg-6">
                <h3 className="fw-bold mb-4">Nimm Kontakt auf:</h3>
                <p>
                    Du kannst den/die Ansprechpartner des Objekts direkt kontaktieren,
                    indem Du Dein Anliegen in das nebenstehende Nachrichten-Feld
                    schreibst. Deine Kontaktdaten werden dem Objektanbieter in diesem
                    Fall ebenfalls übermittelt. Informationen zu Suchprofilen in Deinem
                    Account werden NICHT automatisch geteilt.
                </p>
                <p>
                    Alternativ kannst Du natürlich auch die direkten Kontaktdaten
                    des/der Ansprechpartner/s nutzen, um Dich zum Objektangebot auszutauschen.
                </p>
            </div>
            <div className="col-12 col-lg-6">
                {alert !== undefined &&
                    <AlertBox alert={alert} autoDismiss={false} />
                }
                {showMessageForm === true &&
                    <>
                        <MessageForm
                            formData={messageFormData}
                            setFormData={setMessageFormData}
                            formValidationHandler={formValidationHandler}
                        />
                        <div className="d-flex justify-content-end mt-4">
                            <button className="btn btn-primary py-1 px-5" onClick={sendMessage}>Nachricht senden</button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default Message;
