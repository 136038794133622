enum MatchingResultStatus {
    NewlyCreated = 0,
    RecentlyUpdated = 1,
    NewlyFound = 2
}

const getMatchingResultStatusLabel = (matchingResultStatus: MatchingResultStatus): string => {
    switch (matchingResultStatus) {
        case MatchingResultStatus.NewlyCreated:
            return 'neu eingestellt';
        case MatchingResultStatus.RecentlyUpdated:
            return 'aktualisiert';
        case MatchingResultStatus.NewlyFound:
            return 'neu gefunden';
        default:
            throw new Error();
    }
};

export default MatchingResultStatus;
export {getMatchingResultStatusLabel};
