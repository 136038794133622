import progressBarStyle from './ProgressBar.module.scss';
import React from 'react';

interface ProgressBarProps {
    readonly percentage: number;
    readonly color?: 'accent' | 'grey' | 'lightGrey' | 'darkGrey';
    readonly isBackgroundTransparent?: boolean;
    readonly size?: 'small' | 'big';
}

const ProgressBar = (props: ProgressBarProps): React.JSX.Element => {
    const buildInnerDivClassName = (): string => {
        return 'progress-bar rounded ' + buildColorStyle();
    };

    const buildColorStyle = (): string => {
        switch (props.color) {
            case 'grey':
                return progressBarStyle.grey;
            case 'lightGrey':
                return progressBarStyle.lightGrey;
            case 'darkGrey':
                return progressBarStyle.darkGrey;
            case 'accent':
            default:
                return progressBarStyle.accent;
        }
    };

    const buildOuterDivClassName = (): string => {
        return 'progress ' + buildBackgroundStyle() + ' ' + buildSizeStyle();
    };

    const buildBackgroundStyle = (): string => {
        switch (props.isBackgroundTransparent) {
            case true:
                return progressBarStyle.bgTransparent;
            case false:
            default:
                return '';
        }
    };

    const buildSizeStyle = (): string => {
        switch (props.size) {
            case 'small':
                return progressBarStyle.sizeSmall;
            case 'big':
            default:
                return ''
        }
    };

    return (
        <div className={buildOuterDivClassName()}>
            <div className={buildInnerDivClassName()} style={{width: props.percentage + '%'}} role="progressbar" aria-valuenow={props.percentage} aria-valuemin={0} aria-valuemax={100}></div>
        </div>
    );
};

export default ProgressBar;
