import PaymentService from '../../shared/Payment/PaymentService';
import UserService from '../../shared/User/UserService';
import User from '../../shared/Entity/User/User';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import OrderStep from '../../shared/Entity/MatchPoint/OrderStep';
import PurchaseMethod from '../../shared/Entity/Payment/PurchaseMethod';
import Currency from '../../shared/Entity/Currency';
import MatchPointCard from './MatchPointCard';
import OrderOverviewInformation from './OrderOverviewInformation';
import Price from './Price';
import BillingAddressEdit from '../User/BillingAddressEdit';
import Overlay from '../../shared/Component/Overlay/Overlay';
import CheckboxField from '../../shared/Component/Form/Field/CheckboxField';
import Spinner from '../../../components/Spinner';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface OrderOverviewProps {
    readonly paymentService: PaymentService;
    readonly setOrderStep: (orderStep: OrderStep) => void;
    readonly userService: UserService;
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly taxRate: number;
    readonly amount: number;
    readonly priceNet: number;
    readonly priceGross: number;
    readonly billingAddress: BillingAddress | null | undefined;
    readonly setBillingAddress: (billingAddress: BillingAddress | null) => void;
    readonly purchaseMethod: PurchaseMethod | null;
    readonly setPurchaseMethod: (purchaseMethod: PurchaseMethod) => void;
}

const OrderOverview = (props: OrderOverviewProps): React.JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [showBillingAddressEditOverlay, setShowBillingAddressEditOverlay] = useState<boolean>(false);

    useEffect((): void => {
        fetchBillingAddress();
    }, []);

    useEffect((): void => {
        if (props.billingAddress !== null) {
            return;
        }

        persistBillingAddress();
    }, [props.billingAddress]);

    const fetchBillingAddress = async (): Promise<void> => {
        try {
            props.setBillingAddress(await props.userService.fetchBillingAddressFromApiByUser(props.user));
        } catch (error) {
            throw error;
        }
    };

    const persistBillingAddress = async (): Promise<void> => {
        if (props.billingAddress !== null) {
            return;
        }

        try {
            props.setBillingAddress(await props.userService.persistBillingAddress(props.user, BillingAddress.createFromUser(props.user)));
        } catch (error) {
            throw error;
        }
    };

    const onBillingAddressUpdated = (billingAddress: BillingAddress): void => {
        props.setBillingAddress(billingAddress);
    };

    const onBillingAddressEditClose = (): void => {
        setShowBillingAddressEditOverlay(false);
    };

    const handlePurchaseMethodChange = (changeEvent: React.ChangeEvent<HTMLInputElement>): void => {
        if (changeEvent.target.name === 'onlinePurchaseMethod' && changeEvent.target.checked === true) {
            props.setPurchaseMethod(PurchaseMethod.Online);
        }

        if (changeEvent.target.name === 'bankTransferPurchaseMethod' && changeEvent.target.checked === true) {
            props.setPurchaseMethod(PurchaseMethod.BankTransfer);
        }
    };

    const goToNextStep = (): void => {
        if (props.purchaseMethod === PurchaseMethod.Online) {
            props.setOrderStep(OrderStep.OnlinePayment);
        }

        if (props.purchaseMethod === PurchaseMethod.BankTransfer) {
            props.setOrderStep(OrderStep.InvoicePayment);
        }
    };

    const buildPaymentMethodText = (): string | undefined => {
        if (props.purchaseMethod === PurchaseMethod.Online) {
            return 'Online-Zahlung (Details im nächsten Schritt)';
        }

        if (props.purchaseMethod === PurchaseMethod.BankTransfer) {
            return 'Auf Rechnung';
        }

        return undefined;
    };

    if (props.billingAddress === undefined || props.billingAddress === null || isLoading === true) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid pb-4">
            <div className="d-flex align-items-center mb-3">
                <Link to="" onClick={(): void => props.setOrderStep(OrderStep.MatchPointSelection)}>
                    <i className="bi bi-arrow-left-short text-secondary fs-lg"></i>
                </Link>
                <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">Bestellübersicht</h1>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-xl-9">
                    <div className="row">
                        <div className="col-12 col-xl-4 mb-4 mb-xl-0">
                            <MatchPointCard header="Du kaufst">
                                <div className="text-center mb-2">
                                    <div className="fs-2 text-primary">{props.amount}</div>
                                    <div className="fs-2">MatchPoints</div>
                                </div>
                                <div className="text-center">
                                    <div className="fs-4">zum Preis von</div>
                                    <Price
                                        user={props.user}
                                        numberFormat={props.numberFormat}
                                        currency={props.currency}
                                        amount={props.amount}
                                        priceNet={props.priceNet}
                                        priceGross={props.priceGross}
                                        showPricePerMatchPoint={false}
                                    />
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-secondary mb-auto mt-4" onClick={(): void => props.setOrderStep(OrderStep.MatchPointSelection)}>Bestellung ändern</button>
                                </div>
                            </MatchPointCard>
                        </div>
                        <div className="col-12 col-xl-4 mb-4 mb-xl-0">
                            <MatchPointCard header="Deine Rechnungsadresse">
                                <div className="text-center">
                                    {props.user.naturalPerson === false &&
                                        <div>{props.billingAddress.companyName}</div>
                                    }
                                    <div>{props.billingAddress.firstName} {props.billingAddress.lastName}</div>
                                    <div>{props.billingAddress.streetName} {props.billingAddress.houseNumber}</div>
                                    <div>{props.billingAddress.postalCode} {props.billingAddress.placeName}</div>
                                    <div className="mt-3">{props.billingAddress.email}</div>

                                    <button className="btn btn-secondary mb-auto mt-4" onClick={(): void => setShowBillingAddressEditOverlay(true)}>Rechnungsadresse ändern</button>
                                </div>
                                <Overlay show={showBillingAddressEditOverlay} setShow={setShowBillingAddressEditOverlay} title="Rechnungsadresse ändern">
                                    <BillingAddressEdit billingAddressId={props.billingAddress.id!} user={props.user} onBillingAddressUpdated={onBillingAddressUpdated} onCloseButtonClick={onBillingAddressEditClose} />
                                </Overlay>
                            </MatchPointCard>
                        </div>
                        <div className="col-12 col-xl-4 mb-4 mb-xl-0">
                            <MatchPointCard header="Lieferung">
                                <div className="text-center">
                                    Es handelt sich bei den MatchPoints um ein digitales Produkt, das Deinem Konto nach
                                    erfolgreicher Buchung gutgeschrieben wird.
                                </div>
                                {props.user.naturalPerson === true &&
                                    <div className="text-center mt-3">
                                        Dadurch erlischt das gesetzlich vorgeschriebene Widerrufsrecht.<br />
                                        <Link to="https://www.llasm.de/widerruf" target="_blank">Unser Widerrufsrecht einsehen.</Link>
                                    </div>
                                }
                                <div className="text-center mt-3">
                                    Es entstehen keine Lieferkosten.
                                </div>
                            </MatchPointCard>
                        </div>
                        <div className="col-12 mt-4">
                            {props.paymentService.canDoBankTransferPayment(props.user) === true &&
                                <div className="mb-3">
                                    <CheckboxField
                                        label="Ich möchte auf Rechnung kaufen"
                                        name="bankTransferPurchaseMethod"
                                        checked={props.purchaseMethod === PurchaseMethod.BankTransfer}
                                        onChange={handlePurchaseMethodChange}
                                    />
                                </div>
                            }
                            {props.paymentService.canDoOnlinePayment(props.user) === true &&
                                <div className="mb-3">
                                    <CheckboxField
                                        label="Ich möchte online zahlen (Auswahl der Zahlungsmethode im nächsten Schritt)"
                                        name="onlinePurchaseMethod"
                                        checked={props.purchaseMethod === PurchaseMethod.Online}
                                        onChange={handlePurchaseMethodChange}
                                    />
                                </div>
                            }
                            <div>
                                <div className="fs-5 mb-3">
                                    Für den Kauf von MatchPoints gelten die Regelungen unserer <Link to="/agb" target="_blank">AGB</Link>.
                                </div>
                                {props.purchaseMethod !==  null &&
                                    <button onClick={goToNextStep} className="btn btn-primary align-items-center">
                                        WEITER ZUR KASSE
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xl-3">
                    <MatchPointCard header="Bestellübersicht">
                        <OrderOverviewInformation
                            user={props.user}
                            numberFormat={props.numberFormat}
                            currency={props.currency}
                            taxRate={props.taxRate}
                            amount={props.amount}
                            priceNet={props.priceNet}
                            priceGross={props.priceGross}
                            billingAddress={props.billingAddress}
                            paymentMethodText={buildPaymentMethodText()}
                        />
                    </MatchPointCard>
                </div>
            </div>
        </div>
    );
};

export default OrderOverview;
