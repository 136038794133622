import DocumentService from './DocumentService';
import Document from '../Entity/Document/Document';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import FontAwesomeIcon from '../Component/Icon/FontAwesomeIcon';
import LoadingIndicator from '../../../components/LoadingIndicator';
import documentCollapseCardStyle from './DocumentCollapseCard.module.scss';
import React, {useState} from 'react';

interface DocumentCollapseCardProps {
    readonly documents: Document[];
    readonly buildDocumentGetPath?: (document: Document) => string;
}

const documentService: DocumentService = new DocumentService(process.env.REACT_APP_LLASM_API_URL!);

const DocumentCollapseCard = (props: DocumentCollapseCardProps): React.JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const downloadDocument = async (document: Document): Promise<void> => {
        if (props.buildDocumentGetPath === undefined) {
            return;
        }

        setIsLoading(true);

        try {
            const getPath: string = props.buildDocumentGetPath(document);

            await documentService.downloadDocument(getPath);
        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <CollapseCard cardType="shadow" title="Dokumente" className="mb-3">
            {isLoading === true &&
                <div className={documentCollapseCardStyle.loadingIndicatorContainer}>
                    <LoadingIndicator />
                </div>
            }
            {props.documents.map((document: Document): React.JSX.Element => (
                <>
                    {document.id !== undefined &&
                        <div key={'document-' + document.id} className="d-flex cursor-pointer mb-2" onClick={(): Promise<void> => downloadDocument(document)}>
                            <FontAwesomeIcon iconName="file-pdf" iconType="regular" className="me-2 fs-2" />
                            <span className="fs-4 text-truncate">
                                <u>
                                    {document.title}
                                </u>
                            </span>
                        </div>
                    }
                </>
            ))}
        </CollapseCard>
    );
};

export default DocumentCollapseCard;
