import FormData from '../Entity/Form/FormData';
import FieldValidationDefinition from './FieldValidationDefinition';

class RepetitionValidationDefinition<T> extends FieldValidationDefinition<T> {
    private readonly _referenceFieldName: string;

    constructor(fieldName: string, referenceFieldName: string, message: string | null) {
        super(fieldName, message);

        this._referenceFieldName = referenceFieldName;
    }

    get referenceFieldName(): string {
        return this._referenceFieldName;
    }

    public check(formData: FormData<T>): boolean {
        return (formData.data as any)[this._referenceFieldName] === (formData.data as any)[this._fieldName];
    }
}

export default RepetitionValidationDefinition;
