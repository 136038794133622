import Conversation from './Conversation';
import ConversationType from './ConversationType';
import ReferenceType from './ReferenceType';
import SettlementConcept from './SettlementConcept';
import Property from './Property';
import ParticipatingUser from './ParticipatingUser';
import PropertyInterest from '../PropertyInterest/PropertyInterest';
import ContactIntention from '../ContactIntention/ContactIntention';
import PropertyInterestResponse from '../../../../api/Llasm/PropertyInterest/PropertyInterestResponse';
import ProviderSeekerConversationResponse from '../../../../api/Llasm/Messaging/ProviderSeekerConversationResponse';

class ProviderSeekerConversation extends Conversation {
    private _settlementConcept: SettlementConcept;

    private _property: Property;

    private _correspondingParticipatingUser: ParticipatingUser;

    private _contactIntention: ContactIntention | null = null;

    private _hasMessages: boolean;

    private _propertyInterests: PropertyInterest[] = [];

    public static createFromProviderSeekerConversationResponse(providerSeekerConversationResponse: ProviderSeekerConversationResponse): ProviderSeekerConversation {
        const providerSeekerConversation: ProviderSeekerConversation = new this(
            SettlementConcept.createFromSettlementConceptResponse(providerSeekerConversationResponse.settlementConcept),
            Property.createFromPropertyResponse(providerSeekerConversationResponse.property)
        );

        providerSeekerConversation._id = providerSeekerConversationResponse.id;
        providerSeekerConversation._unreadMessages = providerSeekerConversationResponse.unreadMessages;

        if (providerSeekerConversationResponse.contactIntention !== null) {
            providerSeekerConversation._contactIntention = ContactIntention.createFromContactIntentionResponse(providerSeekerConversationResponse.contactIntention);
        }

        providerSeekerConversation._hasMessages = providerSeekerConversationResponse.hasMessages;

        providerSeekerConversation._propertyInterests = providerSeekerConversationResponse.propertyInterests.map(
            (propertyInterest: PropertyInterestResponse): PropertyInterest => {
                return PropertyInterest.createFromPropertyInterestResponse(propertyInterest);
            }
        );

        providerSeekerConversation._createdAt = new Date(providerSeekerConversationResponse.createdAt);

        if (providerSeekerConversationResponse.lastMessageAt !== null) {
            providerSeekerConversation._lastMessageAt = new Date(providerSeekerConversationResponse.lastMessageAt);
        }

        return providerSeekerConversation;
    }

    public constructor(settlementConcept: SettlementConcept, property: Property) {
        super(ConversationType.ProviderSeeker);

        this._settlementConcept = settlementConcept;
        this._property = property;

        if (
            this._settlementConcept.participatingUser.id !== null
            && this._property.participatingUser.id === null
        ) {
            this._correspondingParticipatingUser = property.participatingUser;

            this._firstReferenceFrom = property.participatingUser;
            this._secondReferenceFrom = settlementConcept.participatingUser;

            this._firstReference = property.title;
            this._secondReference = settlementConcept.reference + ': ' + settlementConcept.title;

            this._firstReferenceType = ReferenceType.Property;
            this._secondReferenceType = ReferenceType.SettlementConcept;
        }

        if (
            this._settlementConcept.participatingUser.id === null
            && this._property.participatingUser.id !== null
        ) {
            this._correspondingParticipatingUser = settlementConcept.participatingUser;

            this._firstReferenceFrom = settlementConcept.participatingUser;
            this._secondReferenceFrom = property.participatingUser;

            this._firstReference = settlementConcept.reference + ': ' + settlementConcept.title;
            this._secondReference = property.title;

            this._firstReferenceType = ReferenceType.SettlementConcept;
            this._secondReferenceType = ReferenceType.Property;
        }
    }

    get settlementConcept(): SettlementConcept {
        return this._settlementConcept;
    }

    set settlementConcept(value: SettlementConcept) {
        this._settlementConcept = value;
    }

    get property(): Property {
        return this._property;
    }

    set property(value: Property) {
        this._property = value;
    }

    get correspondingParticipatingUser(): ParticipatingUser {
        return this._correspondingParticipatingUser;
    }

    set correspondingParticipatingUser(value: ParticipatingUser) {
        this._correspondingParticipatingUser = value;
    }

    get contactIntention(): ContactIntention | null {
        return this._contactIntention;
    }

    set contactIntention(value: ContactIntention | null) {
        this._contactIntention = value;
    }

    get hasMessages(): boolean {
        return this._hasMessages;
    }

    set hasMessages(value: boolean) {
        this._hasMessages = value;
    }

    get propertyInterests(): PropertyInterest[] {
        return this._propertyInterests;
    }

    set propertyInterests(value: PropertyInterest[]) {
        this._propertyInterests = value;
    }

    public buildIdentifier(): string {
        return this.conversationType + '-' + this._id;
    }

    public updateFromProviderSeekerConversation(providerSeekerConversation: ProviderSeekerConversation): void {
        this.updateFromConversation(providerSeekerConversation);

        this._settlementConcept = providerSeekerConversation.settlementConcept;
        this._property = providerSeekerConversation.property;
        this._correspondingParticipatingUser = providerSeekerConversation.correspondingParticipatingUser;
        this._contactIntention = providerSeekerConversation.contactIntention;
        this._hasMessages = providerSeekerConversation.hasMessages;
        this._propertyInterests = providerSeekerConversation.propertyInterests;
    }
}

export default ProviderSeekerConversation;
