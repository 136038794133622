import IconProps from './IconProps';
import bootstrapIconStyle from './BootstrapIcon.module.scss';
import React from 'react';

interface BootstrapIconProps extends IconProps {
    color?: 'primary' | 'grey' | 'lightgrey';
}

const BootstrapIcon = (props: BootstrapIconProps): React.JSX.Element => {
    const buildClassName = (): string => {
        let className: string = 'bi bi-' + props.iconName;

        if (props.className !== undefined) {
            className += ' ' + props.className;
        }

        if (props.color !== undefined) {
            switch (props.color) {
                case 'primary':
                    className += ' ' + bootstrapIconStyle.colorPrimary;

                    break;
                case 'grey':
                    className += ' ' + bootstrapIconStyle.colorGrey;

                    break;
                case 'lightgrey':
                    className += ' ' + bootstrapIconStyle.colorLightgrey;

                    break;
            }
        }

        return className;
    };

    return <i className={buildClassName()}></i>;
};

export default BootstrapIcon;
