import MarketplaceLotDuration from './MarketplaceLotDuration';

class MarketplaceLotPurchase {
    private _amountOfMarketplaceLots: number;

    private _marketplaceLotDuration: MarketplaceLotDuration;

    private _amountOfMatchPoints: number;

    get amountOfMarketplaceLots(): number {
        return this._amountOfMarketplaceLots;
    }

    set amountOfMarketplaceLots(value: number) {
        this._amountOfMarketplaceLots = value;
    }

    get marketplaceLotDuration(): MarketplaceLotDuration {
        return this._marketplaceLotDuration;
    }

    set marketplaceLotDuration(value: MarketplaceLotDuration) {
        this._marketplaceLotDuration = value;
    }

    get amountOfMatchPoints(): number {
        return this._amountOfMatchPoints;
    }

    set amountOfMatchPoints(value: number) {
        this._amountOfMatchPoints = value;
    }
}

export default MarketplaceLotPurchase;
