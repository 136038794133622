import MarketplaceService from '../MarketplaceService';
import UserState from '../../Entity/User/UserState';
import MarketplaceLot from '../../Entity/Marketplace/MarketplaceLot';
import Alert from '../../Entity/Alert/Alert';
import MarketplaceLotCard from './MarketplaceLotCard/MarketplaceLotCard';
import PurchaseMarketplaceLotCard from './PurchaseMarketplaceLotCard';
import InfoButtonOverlay from '../../Component/InfoButtonOverlay/InfoButtonOverlay';
import Spinner from '../../../../components/Spinner';
import AlertBox from '../../../../components/AlertBox';
import {useAppDispatch, useAppSelector} from '../../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const marketplaceService: MarketplaceService = new MarketplaceService(process.env.REACT_APP_LLASM_API_URL!);

const MarketplaceLotOverviewPage = (): React.JSX.Element => {
    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const [marketplaceLots, setMarketplaceLots] = useState<MarketplaceLot[]>();

    const dispatch = useAppDispatch();

    const [alert, setAlert] = useState<Alert>();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs', payload: [
                {name: 'Marktplatz', link: '/marktplatz'},
                {name: 'Meine Inserate'},
            ]
        });
    }, [dispatch]);

    useEffect((): void => {
        fetchMarketplaceLots();
    }, []);

    const fetchMarketplaceLots = async (): Promise<void> => {
        setMarketplaceLots(undefined);

        setMarketplaceLots(await marketplaceService.fetchMarketplaceLotsFromApi());
    };

    const onMarketplaceLotCardAction = async (alert: Alert): Promise<void> => {
        setAlert(alert);
        await fetchMarketplaceLots();
    };

    const onMarketplaceLotPurchase = async (alert: Alert): Promise<void> => {
        setAlert(alert);
        await fetchMarketplaceLots();
    };

    if (marketplaceLots === undefined || user === undefined) {
        return <Spinner />;
    }

    return (
        <div className="container-fluid">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-6 d-flex align-items-center">
                    <Link to="/marktplatz"><i className="bi bi-arrow-left-short text-secondary fs-lg"></i></Link>
                    <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">
                        <span className="d-none d-md-block">Meine </span>Marktplatz-Inserate<sup className="text-primary mb-3">beta</sup>
                    </h1>
                </div>
                <div className="col-md-6 d-none d-md-flex align-items-center justify-content-end px-4">
                    <InfoButtonOverlay title="Meine Inserate" className="fs-5 ms-1 me-3">
                        <div className="mb-3">
                            Um Flächen auf unserem Marktplatz zu veröffentlichen, brauchst Du „digitale Stellplätze".
                            Ein „digitaler Stellplatz" hat eine feste Laufzeit. Die Laufzeit verringert sich nur, wenn ein
                            Objekt mit dem Stellplatz verknüpft ist.
                        </div>
                        <div className="mb-3">
                            Auf dieser Seite kannst Du Stellplätze erwerben und von Dir erfasste Objekte mit den
                            digitalen Stellplätzen verknüpfen oder auch wieder vom Stellplatz entfernen.
                        </div>
                        <div className="mb-3">
                            <strong>Und jetzt kommt das Beste: </strong>
                            Für die Starphase des Marktplatzes hast Du drei Stellplätze mit einer Laufzeit von
                            je 90 Tagen von uns geschenkt bekommen. Diese kannst Du jederzeit mit Objekten belegen.
                        </div>
                    </InfoButtonOverlay>
                </div>
            </div>
            {alert !== undefined &&
                <AlertBox alert={alert} autoDismiss={false} />
            }
            <div className="row">
                {marketplaceLots.map((marketplaceLot: MarketplaceLot): React.JSX.Element => (
                    <div key={'marketplaceLot' + marketplaceLot.id} className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                        <MarketplaceLotCard marketplaceLot={marketplaceLot} user={user} onAction={onMarketplaceLotCardAction} />
                    </div>
                ))}
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                    <PurchaseMarketplaceLotCard onPurchase={onMarketplaceLotPurchase} />
                </div>
            </div>
        </div>
    );
};

export default MarketplaceLotOverviewPage;
