import User from '../../shared/Entity/User/User';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import Currency, {getCurrencySymbol} from '../../shared/Entity/Currency';
import PurchaseMethod from '../../shared/Entity/Payment/PurchaseMethod';
import FormData from '../../shared/Entity/Form/FormData';
import Alert from '../../shared/Entity/Alert/Alert';
import AlertType from '../../shared/Entity/Alert/AlertType';
import MatchPointCard from './MatchPointCard';
import OrderOverviewInformation from './OrderOverviewInformation';
import FormValidationHandler from '../../shared/FormValidationHandler/FormValidationHandler';
import CheckboxField from '../../shared/Component/Form/Field/CheckboxField';
import FieldValidationDefinition from '../../shared/FormValidationHandler/FieldValidationDefinition';
import IsTrueValidationDefinition from '../../shared/FormValidationHandler/IsTrueValidationDefinition';
import Card from '../../shared/Component/Card/Card';
import AlertBox from '../../../components/AlertBox';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface PaymentWrapperProps extends React.PropsWithChildren {
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly taxRate: number;
    readonly amount: number;
    readonly priceNet: number;
    readonly priceGross: number;
    readonly billingAddress: BillingAddress;
    readonly paymentSubmitHandler: () => void;
    readonly paymentInProgress: boolean;
    readonly purchaseMethod: PurchaseMethod;
    readonly paymentMethodText?: string;
}

interface Data {
    withdrawal: boolean;
}

const withdrawalErrorAlert: Alert = new Alert(AlertType.Error, 'Du musst der Verzichtserklärung zustimmen.');

const fieldValidationDefinitions: FieldValidationDefinition<Data>[] = [];

const createFormData = (): FormData<Data> => {
    return {
        data: {
            withdrawal: false
        },
        formValidationHandler: new FormValidationHandler<Data>(fieldValidationDefinitions)
    };
};

const PaymentWrapper = (props: PaymentWrapperProps): React.JSX.Element => {
    const [formData, setFormData] = useState<FormData<Data>>(createFormData());

    const [alert, setAlert] = useState<Alert | undefined>();

    useEffect((): void => {
        if (formData.formValidationHandler === undefined) {
            return;
        }

        if (props.user.naturalPerson === false) {
            return;
        }

        formData.formValidationHandler.addFieldValidationDefinition(
            new IsTrueValidationDefinition<Data>('withdrawal', 'Du musst der Verzichtserklärung zustimmen.')
        );
    }, [props.user, formData]);

    const getPrice = (): number => {
        if (props.user.naturalPerson === true) {
            return props.priceGross;
        }

        return props.priceNet;
    };

    const handleWithdrawalChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name !== 'withdrawal') {
            return;
        }

        formData.data.withdrawal = event.target.checked;

        setFormData(formData);

        validateField(event.target.name);
    };

    const validateField = (fieldName: string): void => {
        if (formData.formValidationHandler === undefined) {
            return;
        }

        formData.formValidationHandler.validateField(fieldName, formData);

        setFormData({...formData, errors: formData.errors});
    };

    const paymentSubmitHandler = (): void => {
        if (formData.formValidationHandler !== undefined) {
            formData.formValidationHandler.validate(formData);

            if (formData.formValidationHandler.hasErrors(formData) === true) {
                setAlert(withdrawalErrorAlert);

                return;
            }

            setAlert(undefined);
        }

        props.paymentSubmitHandler();
    };

    return (
        <div className="row">
            <div className="col-12 col-md-7 col-xl-9 mb-4 mb-xl-0">
                {alert !== undefined &&
                    <div className="mb-3">
                        <AlertBox alert={alert} autoDismiss={false} />
                    </div>
                }

                <Card cardType="shadow" className="p-3">
                    <div className="mb-3">
                        <div className="fs-4 mb-2">
                            Du kaufst {props.amount} MatchPoints für {getCurrencySymbol(props.currency)} {props.numberFormat.format(Number(getPrice()))}
                            {props.purchaseMethod === PurchaseMethod.BankTransfer &&
                                <> auf Rechnung</>
                            }
                        </div>
                        <div>
                            Für den Kauf von MatchPoints gelten die Regelungen unserer <Link to="/agb" target="_blank">AGB</Link>.
                        </div>
                    </div>
                    {props.children}
                    {props.user.naturalPerson === true &&
                        <div className="mb-3">
                            <CheckboxField
                                label="Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrags begonnen wird. Mir ist bekannt, dass mit Beginn der Ausführung mein Widerrufsrecht erlischt."
                                name="withdrawal"
                                checked={formData.data.withdrawal}
                                disabled={props.paymentInProgress}
                                onChange={handleWithdrawalChange}
                                formErrors={formData.errors}
                            />
                        </div>
                    }
                    <button onClick={paymentSubmitHandler} disabled={props.paymentInProgress} className="btn btn-primary align-items-center mt-3 mb-3">KOSTENPFLICHTIG BESTELLEN</button>
                </Card>
            </div>
            <div className="col-12 col-md-5 col-xl-3">
                <MatchPointCard header="Bestellübersicht">
                    <OrderOverviewInformation
                        user={props.user}
                        numberFormat={props.numberFormat}
                        currency={props.currency}
                        taxRate={props.taxRate}
                        amount={props.amount}
                        priceNet={props.priceNet}
                        priceGross={props.priceGross}
                        billingAddress={props.billingAddress}
                        paymentMethodText={props.paymentMethodText}
                    />
                </MatchPointCard>
            </div>
        </div>
    );
};

export default PaymentWrapper;
