import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import ImageEntity from '../Entity/Image/Image';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import SettlementConceptService from './SettlementConceptService';
import SettlementConceptDetailInformation from './SettlementConceptDetailInformation/SettlementConceptDetailInformation';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import Overlay from '../Component/Overlay/Overlay';
import Card from '../Component/Card/Card';
import CardContent from '../Component/Card/CardContent';
import ImageSliderCardMedia from '../Component/Card/ImageSliderCardMedia';
import StatusIndicator from '../Component/StatusIndicator/StatusIndicator';
import Tooltip from '../Component/Tooltip/Tooltip';
import noImage from '../../../img/no-settlement-concept-image.jpg';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

interface SettlementConceptCardProps {
    readonly settlementConcept: SettlementConcept;
}

const SettlementConceptCard = (props: SettlementConceptCardProps): React.JSX.Element => {
    const [showDetailInformationOverlay, setShowDetailInformationOverlay] = useState<boolean>(false);

    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const buildThumbnailImageGetPath = (image: ImageEntity): string => {
        return SettlementConceptService.buildThumbnailImageApiPath(props.settlementConcept, image);
    };

    return (
        <Card cardType="shadow" className="position-relative h-100">
            <StatusIndicator active={props.settlementConcept.active} right={-10} top={-10} />
            <ImageSliderCardMedia
                images={props.settlementConcept.images}
                noImage={noImage}
                buildThumbnailImageGetPath={buildThumbnailImageGetPath}
                cardMediaContainerHeight={cardMediaContainerHeight}
                setCardMediaContainerHeight={setCardMediaContainerHeight}
            />
            <CardContent className="px-2 px-md-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="fw-bolder">{props.settlementConcept.title}</div>
                    <div className="text-nowrap ms-2">
                        <button className="btn btn-outline-secondary btn-sm me-2" onClick={(): void => setShowDetailInformationOverlay(true)}>
                            <i className="bi bi-eye-fill"></i>
                        </button>
                        <Link to={'/suchprofile/' + props.settlementConcept.id + ''} className="btn btn-sm btn-secondary">
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Suchprofil-Nr.">
                                <BootstrapIcon iconName="rocket-takeoff" />
                            </Tooltip>
                        </div>
                        <div className="fw-bold">
                            #{props.settlementConcept.reference}
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Marke/Brand">
                                <BootstrapIcon iconName="list-stars" />
                            </Tooltip>
                        </div>
                        <div>
                            {props.settlementConcept.brand.brandName}
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Branche(n)">
                                <BootstrapIcon iconName="signpost-2" />
                            </Tooltip>
                        </div>
                        <div>
                            <ul className="ps-0 list-unstyled mb-0">
                                {props.settlementConcept.industryClassifications.map((industryClassification: IndustryClassification, index: number): React.JSX.Element => (
                                    <li key={'industryClassification-' + index} className="fs-6 me-1">
                                        <React.Fragment>{industryClassification.name}</React.Fragment>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Flächenbedarf">
                                <BootstrapIcon iconName="bounding-box-circles" />
                            </Tooltip>
                        </div>
                        <div>
                            <div>{props.settlementConcept.propertyCriteria.areaSizeMinimum} bis {props.settlementConcept.propertyCriteria.areaSizeMaximum} m²</div>
                        </div>
                    </div>
                </div>
            </CardContent>
            <Overlay title={props.settlementConcept.title} show={showDetailInformationOverlay} setShow={setShowDetailInformationOverlay}>
                <div className="container">
                    <SettlementConceptDetailInformation settlementConcept={props.settlementConcept} />
                </div>
            </Overlay>
        </Card>
    );
};

export default SettlementConceptCard;
