import ImageEntity from '../../Entity/Image/Image';
import Image from '../../Image/Image';
import CardMedia from './CardMedia';
import Slider from '../Slider/Slider';
import React from 'react';

interface ImageSliderCardMediaProps {
    readonly images: ImageEntity[];
    readonly noImage: string;
    readonly buildThumbnailImageGetPath: (image: ImageEntity) => string;
    readonly cardMediaContainerHeight: number | undefined;
    readonly setCardMediaContainerHeight: (cardMediaContainerHeight: number) => void;
}

const ImageSliderCardMedia = (props: ImageSliderCardMediaProps): React.JSX.Element => {
    return (
        <CardMedia setContainerHeight={props.setCardMediaContainerHeight}>
            {(props.images.length === 0) ? (
                <div className="d-flex align-items-center" style={{height: props.cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                    <img src={props.noImage} alt="Kein Bild" className="w-100" />
                </div>
            ) : (
                <Slider
                    config={{
                        type: 'slider',
                        showArrows: props.images.length > 0,
                        arrowsPosition: 'inside',
                        rewind: true,
                        perView: 1,
                        bound: true
                    }}
                >
                    {props.images.map((image: ImageEntity): React.JSX.Element => (
                        <div key={'image-' + image.id} className="d-flex align-items-center" style={{height: props.cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                            <Image image={image} buildImageGetPath={props.buildThumbnailImageGetPath} className="w-100" />
                        </div>
                    ))}
                </Slider>
            )}
        </CardMedia>
    );
};

export default ImageSliderCardMedia;
