import MatchingService from '../../shared/Matching/MatchingService';
import Property from '../../shared/Entity/Property/Property';
import SettlementConcept from '../../shared/Entity/SettlementConcept/SettlementConcept';
import AdPartnerType from '../../shared/Entity/AdPartner/AdPartnerType';
import PropertySlider from '../../shared/Property/PropertySlider';
import SettlementConceptSlider from '../../shared/SettlementConcept/SettlementConceptSlider';
import MatchingOverview from '../../shared/Matching/MatchingOverview/MatchingOverview';
import AdPartnerSection from '../AdPartner/AdPartnerSection';
import MarketplaceBanner from '../MarketplaceBanner/MarketplaceBanner';
import ReaBanner2024 from '../ReaBanner2024/ReaBanner2024';
import WebinarBanner from '../WebinarBanner/WebinarBanner';
import Sidebar from '../../../components/Sidebar';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface DashboardPageProps {
    readonly matchingService: MatchingService;
    readonly settlementConcepts: SettlementConcept[];
    readonly properties: Property[];
}

const DashboardPage = (props: DashboardPageProps): React.JSX.Element => {
    const [hasSettlementConceptsWithContactIntentions, setHasSettlementConceptsWithContactIntentions] = useState<boolean>(false);

    const [hasPropertiesWithContactIntentions, setHasPropertiesWithContactIntentions] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs',
            payload: []
        });
    }, [dispatch]);

    useEffect((): void => {
        setHasSettlementConceptsWithContactIntentions(
            props.settlementConcepts.find((settlementConcept: SettlementConcept): boolean => {
                return settlementConcept.active === true && settlementConcept.hasContactIntentions === true;
            }) !== undefined
        );
    }, [props.settlementConcepts]);

    useEffect((): void => {
        setHasPropertiesWithContactIntentions(
            props.properties.find((property: Property): boolean => {
                return property.active === true && property.hasContactIntentions === true;
            }) !== undefined
        );
    }, [props.properties]);

    return (
        <div className="dashboard container-fluid">
            <div className="row p-1 p-md-4">
                <div className="col-md-9 col-lg-10">
                    <MarketplaceBanner />
                    <ReaBanner2024 />
                    <WebinarBanner />
                    <div className="row g-lg-5 g-3">
                        <div className="col-xl-6 p-0 px-md-2 mb-4">
                            <p className="title ls-1 fw-semibold text-center">Meine Flächen/Ladenlokale</p>
                            <PropertySlider properties={props.properties} />
                            {props.properties.length > 0 &&
                                <Link to="/flaechen" className="btn btn-outline-secondary mx-auto my-4 w-100">Alle Flächen</Link>
                            }
                            {props.properties.length === 0 &&
                                <Link to="/flaechen/anbieten" className="btn btn-outline-secondary mx-auto my-4 w-100">Ladenlokal anlegen</Link>
                            }
                        </div>
                        <div className="col-xl-6 p-0 px-md-2 mb-4">
                            <p className="title ls-1 fw-semibold text-center">Meine Suchprofile</p>
                            <SettlementConceptSlider settlementConcepts={props.settlementConcepts} />
                            {props.settlementConcepts.length > 0 &&
                                <Link to="/suchprofile" className="btn btn-outline-secondary mx-auto my-4 w-100">Alle Suchprofile</Link>
                            }
                            {props.settlementConcepts.length === 0 &&
                                <Link to="/suchprofile/einstellen" className="btn btn-outline-secondary mx-auto my-4 w-100">Suchprofil erstellen</Link>
                            }
                        </div>
                    </div>
                    <AdPartnerSection adPartnerType={AdPartnerType.Platinum} />
                    {(hasSettlementConceptsWithContactIntentions === true || hasPropertiesWithContactIntentions === true) &&
                        <div className="mb-3">
                            <MatchingOverview
                                matchingService={props.matchingService}
                                settlementConcepts={props.settlementConcepts}
                                properties={props.properties}
                            />
                        </div>
                    }
                </div>
                <div className="col-md-3 col-lg-2">
                    <Sidebar
                        sidebarLinks={[
                            {icon: <i className="bi bi-shop fs-xl mt-3 text-secondary"></i>, title: 'Fläche / Ladenlokal anlegen', to: '/flaechen/anbieten', header: 'Nachnutzer finden:'},
                            {icon: <i className="bi bi-rocket-takeoff fs-xl mt-3 text-secondary"></i>, title: 'Suchprofil erstellen', to: '/suchprofile/einstellen', header: 'Expandieren:'},
                            {icon: <><i className="bi bi-bag-heart fs-xl mt-3 text-secondary"></i><sup className="text-primary mb-3">beta</sup></>, title: 'Marktplatz', to: '/marktplatz'},
                            {icon: <i className="bi bi-magic fs-xl mt-3 text-secondary"></i>, title: 'Hall of Inspiration', to: '/hall-of-inspiration/konzepte'},
                            {icon: <i className="bi bi-list-stars fs-xl mt-3 text-secondary"></i>, title: 'Marken / Namen der Geschäfte', to: '/marken'},
                            {icon: <i className="bi bi-person fs-xl mt-3 text-secondary"></i>, title: 'Ansprechpartner', to: '/ansprechpartner', ellipsis: true},
                            {icon: <i className="bi bi-book fs-xl mt-3 text-secondary"></i>, title: 'Glossar', to: 'https://www.llasm.de/glossar'},
                            {icon: <i className="bi bi-lightbulb fs-xl mt-3 text-secondary"></i>, title: 'FAQ', to: 'https://www.llasm.de/faq'},
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
