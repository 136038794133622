class UserPasswordChange {
    private _passwordOld: string;

    private _password: string;

    private _passwordRepeat: string;

    get passwordOld(): string {
        return this._passwordOld;
    }

    set passwordOld(value: string) {
        this._passwordOld = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get passwordRepeat(): string {
        return this._passwordRepeat;
    }

    set passwordRepeat(value: string) {
        this._passwordRepeat = value;
    }
}

export default UserPasswordChange;
