import MarketplaceAdFilter from '../../Entity/Marketplace/MarketplaceAdFilter';
import SpecialObjectCategory from '../../Entity/Property/SpecialObjectCategory';
import SelectOption from '../../Entity/Form/SelectOption';
import SpecialFilterButton from './SpecialFilterButton';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import MultiSelectField from '../../Component/Form/Field/MultiSelectField';
import React from 'react';

interface SpecialFilterProps {
    readonly marketplaceAdFilter: MarketplaceAdFilter;
    readonly onChange: (marketplaceAdFilter: MarketplaceAdFilter) => void;
}

const specialFilterSelectOptions: SelectOption<string>[] = [
    {label: 'Pop-Up', value: 'popUpArea'},
    {label: 'Mixed-Use', value: 'mixedUseArea'},
    {label: 'Kaufhaus', value: 'departmentStore'},
    // {label: 'Co-Working', value: 'coWorkingSpace'}, TODO comment back in once requirements for CoWorkingSpace are clearer
    {label: 'Förderflächen', value: 'fundingAvailable'},
];

const SpecialFilter = (props: SpecialFilterProps): React.JSX.Element => {
    const getSpecialObjectCategoryButtonLabel = (specialObjectCategory: SpecialObjectCategory): React.JSX.Element => {
        switch (specialObjectCategory) {
            case SpecialObjectCategory.PopUpArea:
                return (
                    <div className="d-flex align-items-center">
                        <BootstrapIcon iconName="clock-history" className="me-2" />
                        Pop-Up
                    </div>
                );
            case SpecialObjectCategory.MixedUseArea:
                return (
                    <div className="d-flex align-items-center">
                        <BootstrapIcon iconName="columns-gap" className="me-2" />
                        Mixed-Use
                    </div>
                );
            case SpecialObjectCategory.DepartmentStore:
                return (
                    <div className="d-flex align-items-center">
                        <BootstrapIcon iconName="building" className="me-2" />
                        Kaufhaus
                    </div>
                );
            case SpecialObjectCategory.CoWorkingSpace:
                return (
                    <div className="d-flex align-items-center">
                        <BootstrapIcon iconName="person-workspace" className="me-2" />
                        Co-Working
                    </div>
                );
        }
    };

    const getFundingAvailableButtonLabel = (): React.JSX.Element => {
        return (
            <div className="d-flex align-items-center">
                <BootstrapIcon iconName="cash-coin" className="me-2" />
                Förderflächen
            </div>
        );
    };

    const fetchSelectedSpecialFilterSelectOptions = (): SelectOption<string>[] => {
        const selectedSpecialFilterSelectOptions: SelectOption<string>[] = [];

        props.marketplaceAdFilter.specialObjectCategories.forEach((specialObjectCategory: SpecialObjectCategory): void => {
            switch (specialObjectCategory) {
                case SpecialObjectCategory.PopUpArea:
                    selectedSpecialFilterSelectOptions.push({label: 'Pop-Up', value: 'popUpArea'});
                    break;
                case SpecialObjectCategory.MixedUseArea:
                    selectedSpecialFilterSelectOptions.push({label: 'Mixed-Use', value: 'mixedUseArea'});
                    break;
                case SpecialObjectCategory.DepartmentStore:
                    selectedSpecialFilterSelectOptions.push({label: 'Kaufhaus', value: 'departmentStore'});
                    break;
                case SpecialObjectCategory.CoWorkingSpace:
                    selectedSpecialFilterSelectOptions.push({label: 'Co-Working', value: 'coWorkingSpace'});
                    break;
            }
        });

        if (props.marketplaceAdFilter.fundingAvailable === true) {
            selectedSpecialFilterSelectOptions.push({label: 'Förderflächen', value: 'fundingAvailable'});
        }

        return selectedSpecialFilterSelectOptions;
    };

    const handleSpecialFiltersChange = (specialFilters: readonly SelectOption<string>[]): void => {
        const selectedSpecialObjectCategories: SpecialObjectCategory[] = [];

        props.marketplaceAdFilter.fundingAvailable = false;

        specialFilters.forEach((specialFilter: SelectOption<string>): void => {
            switch (specialFilter.value) {
                case 'popUpArea':
                    selectedSpecialObjectCategories.push(SpecialObjectCategory.PopUpArea);
                    break;
                case 'mixedUseArea':
                    selectedSpecialObjectCategories.push(SpecialObjectCategory.MixedUseArea);
                    break;
                case 'departmentStore':
                    selectedSpecialObjectCategories.push(SpecialObjectCategory.DepartmentStore);
                    break;
                case 'coWorkingSpace':
                    selectedSpecialObjectCategories.push(SpecialObjectCategory.CoWorkingSpace);
                    break;
                case 'fundingAvailable':
                    props.marketplaceAdFilter.fundingAvailable = true;
                    break;
            }
        });

        props.marketplaceAdFilter.specialObjectCategories = selectedSpecialObjectCategories;

        props.onChange(props.marketplaceAdFilter);
    };

    const toggleSpecialObjectCategory = (specialObjectCategory: SpecialObjectCategory): void => {
        const index: number = props.marketplaceAdFilter.specialObjectCategories.indexOf(specialObjectCategory);

        if (index === -1) {
            props.marketplaceAdFilter.specialObjectCategories.push(specialObjectCategory);
        } else {
            props.marketplaceAdFilter.specialObjectCategories.splice(index, 1);
        }

        props.onChange(props.marketplaceAdFilter);
    };

    const toggleFundingAvailable = (): void => {
        props.marketplaceAdFilter.fundingAvailable = props.marketplaceAdFilter.fundingAvailable === false;

        props.onChange(props.marketplaceAdFilter);
    };

    return (
        <>
            <div className="d-none d-lg-flex">
                <div className="fs-5 me-3 d-lg-none d-xl-flex align-items-center">
                    Zeige mir:
                </div>
                <SpecialFilterButton
                    label={getSpecialObjectCategoryButtonLabel(SpecialObjectCategory.PopUpArea)}
                    isActive={props.marketplaceAdFilter.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === true}
                    onClick={(): void => {toggleSpecialObjectCategory(SpecialObjectCategory.PopUpArea)}}
                />
                <SpecialFilterButton
                    label={getSpecialObjectCategoryButtonLabel(SpecialObjectCategory.MixedUseArea)}
                    isActive={props.marketplaceAdFilter.specialObjectCategories.includes(SpecialObjectCategory.MixedUseArea) === true}
                    onClick={(): void => {toggleSpecialObjectCategory(SpecialObjectCategory.MixedUseArea)}}
                />
                <SpecialFilterButton
                    label={getSpecialObjectCategoryButtonLabel(SpecialObjectCategory.DepartmentStore)}
                    isActive={props.marketplaceAdFilter.specialObjectCategories.includes(SpecialObjectCategory.DepartmentStore) === true}
                    onClick={(): void => {toggleSpecialObjectCategory(SpecialObjectCategory.DepartmentStore)}}
                />
                {/* TODO comment back in once requirements for CoWorkingSpace are clearer */}
                {/*<SpecialFilterButton*/}
                {/*    label={getSpecialObjectCategoryButtonLabel(SpecialObjectCategory.CoWorkingSpace)}*/}
                {/*    isActive={props.marketplaceAdFilter.specialObjectCategories.includes(SpecialObjectCategory.CoWorkingSpace) === true}*/}
                {/*    onClick={(): void => {toggleSpecialObjectCategory(SpecialObjectCategory.CoWorkingSpace)}}*/}
                {/*/>*/}
                <SpecialFilterButton
                    label={getFundingAvailableButtonLabel()}
                    isActive={props.marketplaceAdFilter.fundingAvailable}
                    onClick={toggleFundingAvailable}
                />
            </div>
            <div className="d-lg-none">
                <MultiSelectField
                    label="Zeige mir:"
                    name="specialFilters"
                    options={specialFilterSelectOptions}
                    closeMenuOnSelect={false}
                    placeholder="Bitte auswählen"
                    onChange={handleSpecialFiltersChange}
                    value={fetchSelectedSpecialFilterSelectOptions()}
                    isClearable={true}
                />
            </div>
        </>
    );
};

export default SpecialFilter;
