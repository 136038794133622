import Alert from '../../Entity/Alert/Alert';
import PurchaseMarketplaceLotOverlay from './PurchaseMarketplaceLotOverlay';
import Card from '../../Component/Card/Card';
import RoundedContainer from '../../Component/RoundedContainer/RoundedContainer';
import CardMedia from '../../Component/Card/CardMedia';
import CardContent from '../../Component/Card/CardContent';
import CardFooter from '../../Component/Card/CardFooter';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import React, {useState} from 'react';

interface PurchaseMarketplaceLotCardProps {
    readonly onPurchase: (alert: Alert) => void;
}

const PurchaseMarketplaceLotCard = (props: PurchaseMarketplaceLotCardProps): React.JSX.Element => {
    const [showOverlay, setShowOverlay] = useState<boolean>(false);

    const openPurchaseMarketplaceLotOverlay = (): void => {
        setShowOverlay(true);
    };

    return (
        <>
            <Card cardType="shadow" className="position-relative h-100">
                <CardMedia>
                    <RoundedContainer className="d-flex flex-wrap align-items-center h-100" style={{backgroundColor: 'lightgray'}}>
                        <div className="w-100 ms-2 me-2 text-center text-white fs-xxl" style={{cursor: 'pointer'}} onClick={openPurchaseMarketplaceLotOverlay}>
                            <BootstrapIcon iconName="bag-plus-fill" />
                        </div>
                    </RoundedContainer>
                </CardMedia>
                <CardContent>
                    <div className="text-center fs-5 fw-bolder mb-4 text-black-50">
                        Stellplätze buchen
                    </div>
                    <div className="text-center mb-3">
                        Du möchtest Flächen im Marktplatz veröffentlichen? Dann buche Dir hier einen oder mehrere Stellplätze.
                    </div>
                    <div className="text-center">
                        Du kannst Deinen Stellplatz für mehrer Objekte nutzen und diese nach Belieben jederzeit austauschen.
                    </div>
                    <div style={{height: '100px'}}></div>
                </CardContent>
                <CardFooter className="position-absolute bottom-0 w-100">
                    <button className="btn btn-primary mx-auto text-nowrap text-uppercase w-100 mb-2" onClick={openPurchaseMarketplaceLotOverlay}>
                        Stellplätze buchen
                    </button>
                </CardFooter>
            </Card>
            <PurchaseMarketplaceLotOverlay show={showOverlay} setShow={setShowOverlay} onPurchase={props.onPurchase} />
        </>
    );
};

export default PurchaseMarketplaceLotCard;
