enum MarketplaceLotDuration {
    Ninety = 0,
    OneHundredEighty = 1,
    ThreeHundredSixtyFive = 2
}

const getMarketplaceLotDurationLabel = (marketplaceLotDuration: MarketplaceLotDuration): string => {
    switch (marketplaceLotDuration) {
        case MarketplaceLotDuration.Ninety:
            return '90 Tage';
        case MarketplaceLotDuration.OneHundredEighty:
            return '180 Tage';
        case MarketplaceLotDuration.ThreeHundredSixtyFive:
            return '365 Tage';
        default:
            throw new Error();
    }
};

const getMarketplaceLotDurationAmountOfDays = (marketplaceLotDuration: MarketplaceLotDuration): number => {
    switch (marketplaceLotDuration) {
        case MarketplaceLotDuration.Ninety:
            return 90;
        case MarketplaceLotDuration.OneHundredEighty:
            return 180;
        case MarketplaceLotDuration.ThreeHundredSixtyFive:
            return 365;
        default:
            throw new Error();
    }
};

const getMarketplaceLotDurationBaseCost = (marketplaceLotDuration: MarketplaceLotDuration): number => {
    switch (marketplaceLotDuration) {
        case MarketplaceLotDuration.Ninety:
            return 50;
        case MarketplaceLotDuration.OneHundredEighty:
            return 75;
        case MarketplaceLotDuration.ThreeHundredSixtyFive:
            return 125;
        default:
            throw new Error();
    }
};

export default MarketplaceLotDuration;
export {getMarketplaceLotDurationLabel, getMarketplaceLotDurationAmountOfDays, getMarketplaceLotDurationBaseCost};
