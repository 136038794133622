import SettlementConceptService from './SettlementConceptService';
import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import Contact from '../Entity/Contact/Contact';
import ImageEntity from '../Entity/Image/Image';
import PropertyAcquisitionType, {getPropertyAcquisitionTypeLabel} from '../Entity/PropertyAcquisitionType';
import {getParkingLotRequirementTypeLabel} from '../Entity/ParkingLotRequirementType';
import SettlementConceptDetailInformation from './SettlementConceptDetailInformation/SettlementConceptDetailInformation';
import Image from '../Image/Image';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import Overlay from '../Component/Overlay/Overlay';
import Card from '../Component/Card/Card';
import CardMedia from '../Component/Card/CardMedia';
import CardContent from '../Component/Card/CardContent';
import Slider from '../Component/Slider/Slider';
import StatusIndicator from '../Component/StatusIndicator/StatusIndicator';
import Tooltip from '../Component/Tooltip/Tooltip';
import settlementConceptSliderStyle from './SettlementConceptSlider.module.scss';
import noImage from '../../../img/no-settlement-concept-image.jpg';
import level1 from '../../../img/industryClassification/1.jpg';
import level2 from '../../../img/industryClassification/2.jpg';
import level3 from '../../../img/industryClassification/3.jpg';
import level4 from '../../../img/industryClassification/4.jpg';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface SettlementConceptSliderProps {
    readonly settlementConcepts: SettlementConcept[];
}

const minMediumDeviceWidth: number = 768;
let windowWidth: number = window.innerWidth;

const SettlementConceptSlider = (props: SettlementConceptSliderProps): React.JSX.Element => {
    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const [showSettlementConceptDetailInformationOverlay, setShowSettlementConceptDetailInformationOverlay] = useState<boolean>(false);

    const [settlementConceptDetailInformationItem, setSettlementConceptDetailInformationItem] = useState<SettlementConcept>();

    const [showArrows, setShowArrows] = useState<boolean>(true);

    useEffect((): void => {
        const showArrows: boolean = props.settlementConcepts.length > 0 && window.innerWidth > minMediumDeviceWidth;

        setShowArrows(showArrows);

        window.addEventListener('resize', handleWindowResize);
    }, []);

    const handleWindowResize = (): void => {
        if (
            (window.innerWidth < minMediumDeviceWidth && windowWidth >= minMediumDeviceWidth)
            || (window.innerWidth >= minMediumDeviceWidth && windowWidth < minMediumDeviceWidth)
        ) {
            const showArrows: boolean = props.settlementConcepts.length > 0 && window.innerWidth > minMediumDeviceWidth;

            setShowArrows(showArrows);
        }

        windowWidth = window.innerWidth;
    };

    if (props.settlementConcepts.length === 0) {
        return (
            <Card cardType="shadow" className="position-relative carousel-card">
                <CardMedia setContainerHeight={setCardMediaContainerHeight}>
                    <div className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                        <img src={noImage} alt="Kein Bild" className="w-100" />
                    </div>
                </CardMedia>
                <CardContent>
                    <div className="text-center pt-4 mb-3 fw-bold">
                        Du hast derzeit kein Suchprofil erfasst.
                    </div>
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            <Slider
                config={{
                    type: 'slider',
                    showArrows: showArrows,
                    arrowsPosition: 'outside',
                    rewind: true,
                    perView: 1,
                    bound: true
                }}
            >
                {props.settlementConcepts.map((settlementConcept: SettlementConcept): React.JSX.Element => (
                    <SettlementConceptCard
                        key={'settlementConcept-' + settlementConcept.id}
                        settlementConcept={settlementConcept}
                        setShowSettlementConceptDetailInformationOverlay={setShowSettlementConceptDetailInformationOverlay}
                        setSettlementConceptDetailInformationItem={setSettlementConceptDetailInformationItem}
                    />
                ))}
            </Slider>
            {settlementConceptDetailInformationItem !== undefined &&
                <Overlay title={settlementConceptDetailInformationItem.title} show={showSettlementConceptDetailInformationOverlay} setShow={setShowSettlementConceptDetailInformationOverlay}>
                    <div className="container">
                        <SettlementConceptDetailInformation settlementConcept={settlementConceptDetailInformationItem} />
                    </div>
                </Overlay>
            }
        </>
    );
};

interface SettlementConceptCardProps {
    readonly settlementConcept: SettlementConcept;
    readonly setShowSettlementConceptDetailInformationOverlay: (showSettlementConceptDetailInformationOverlay: boolean) => void;
    readonly setSettlementConceptDetailInformationItem: (settlementConcept: SettlementConcept) => void;
}

const getIndustryClassificationImage = (levelOne: number): string => {
    switch (levelOne) {
        case 1:
            return level1;
        case 2:
            return level2;
        case 3:
            return level3;
        case 4:
            return level4;
        default:
            return noImage;
    }
};

const SettlementConceptCard = (props: SettlementConceptCardProps): React.JSX.Element => {
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(false);

    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const showSettlementConceptDetailInformation = (settlementConcept: SettlementConcept): void => {
        props.setSettlementConceptDetailInformationItem(settlementConcept);
        props.setShowSettlementConceptDetailInformationOverlay(true);
    };

    const propertyAcquisitionTypeValues: string[] = props.settlementConcept.propertyCriteria.propertyAcquisitionTypes.map(
        (propertyAcquisitionType: PropertyAcquisitionType): string => getPropertyAcquisitionTypeLabel(propertyAcquisitionType)
    );

    const parkingLotRequirementValue: string = getParkingLotRequirementTypeLabel(props.settlementConcept.propertyCriteria.parkingLotRequirementType);

    const buildImageGetPath = (image: ImageEntity): string => {
        return SettlementConceptService.buildImageApiPath(props.settlementConcept, image);
    };

    return (
        <Card cardType="shadow" className="position-relative carousel-card m-3">
            <StatusIndicator active={props.settlementConcept.active} right={-10} top={-10} />
            <CardMedia setContainerHeight={setCardMediaContainerHeight}>
                {(props.settlementConcept.images.length > 0) ? (
                    <div className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                        <Image image={props.settlementConcept.images[0]} buildImageGetPath={buildImageGetPath} className="w-100" />
                    </div>
                ) : (
                    <div style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                        <img src={getIndustryClassificationImage(props.settlementConcept.industryClassifications[0]!.levelOne)} alt="Kein Bild" className="w-100" />
                    </div>
                )}
            </CardMedia>
            <CardContent className="px-2 px-md-4">
                <div className="card-slide-title d-flex justify-content-between align-items-start mb-3">
                    <p className="fw-bolder">{props.settlementConcept.title}</p>
                    <div className="text-nowrap ms-2">
                        <button className="btn btn-outline-secondary btn-sm me-2" onClick={(): void => showSettlementConceptDetailInformation(props.settlementConcept)}>
                            <i className="bi bi-eye-fill"></i>
                        </button>
                        <Link to={'/suchprofile/' + props.settlementConcept.id} className="btn btn-sm btn-secondary">
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    </div>
                </div>
                <div className="card-slide-main-details mb-4">
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Suchprofil-Nr.">
                                <BootstrapIcon iconName="rocket-takeoff" />
                            </Tooltip>
                        </div>
                        <div className="fw-bold">
                            #{props.settlementConcept.reference}
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Branche(n)">
                                <BootstrapIcon iconName="signpost-2" />
                            </Tooltip>
                        </div>
                        <div>
                            <ul className="ps-0 list-unstyled mb-0">
                                {props.settlementConcept.industryClassifications.map((industryClassification: IndustryClassification, index: number): React.JSX.Element => (
                                    <li key={'industryClassification-' + index} className="me-1">
                                        <React.Fragment>{industryClassification.name}</React.Fragment>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Flächenbedarf">
                                <BootstrapIcon iconName="bounding-box-circles" />
                            </Tooltip>
                        </div>
                        <div>
                            <div>{props.settlementConcept.propertyCriteria.areaSizeMinimum} bis {props.settlementConcept.propertyCriteria.areaSizeMaximum} m²</div>
                        </div>
                    </div>
                </div>
                {/*
                    TODO: progress ring will be a feature after post release
                    <div className="card-slide-progress d-flex align-items-center gap-3 mb-4">
                        <ProgressRing stroke={7} radius={26} progress={77} />
                        <div>
                            <span className="d-block mb-1"><b>{77}%</b> vollständig</span>
                            <span className="d-block">Fülle alle Informationen aus für ein besseres Matching.</span>
                        </div>
                    </div>
                */}
                <div className="card-action row mb-0">
                    <div className="col-3 col-md-6 text-center text-lg-end">
                        <button
                            type="button"
                            className="btn btn-default border-0 text-primary fs-2"
                            onClick={(): void => setIsDetailsExpanded((prev: boolean): boolean => prev == false)}
                        >
                            <i className={`bi ${isDetailsExpanded === true ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                        </button>
                    </div>
                </div>
            </CardContent>
            {isDetailsExpanded === true &&
                <div className={'p-4 card-shadow bg-light h-100 ' + settlementConceptSliderStyle.drawerBorderRadius}>
                    {props.settlementConcept.propertyCriteria.secondaryAreaSize !== null &&
                        <div className="d-flex justify-content-start mb-3">
                            <div className="me-3">
                                <Tooltip title="erweiterter Flächenbedarf">
                                    <BootstrapIcon iconName="bounding-box" />
                                </Tooltip>
                            </div>
                            <div>
                                <div>{props.settlementConcept.propertyCriteria.secondaryAreaSize} m² Nebenfläche</div>
                            </div>
                        </div>
                    }
                    {propertyAcquisitionTypeValues.length > 0 &&
                        <div className="d-flex justify-content-start mb-3">
                            <div className="me-3">
                                <Tooltip title="Angebotsarten">
                                    <BootstrapIcon iconName="cash" />
                                </Tooltip>
                            </div>
                            <div>
                                <div>{propertyAcquisitionTypeValues.join(', ')}</div>
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Parkplätze am Objekt">
                                <BootstrapIcon iconName="p-square-fill" />
                            </Tooltip>
                        </div>
                        <div>
                            <div>Parkplätze sind {parkingLotRequirementValue}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Kontaktperson(en)">
                                <BootstrapIcon iconName="person-lines-fill" />
                            </Tooltip>
                        </div>
                        <div>
                            {props.settlementConcept.contacts.map((contact: Contact): string => contact.buildDisplayName()).join(' / ')}
                        </div>
                    </div>
                </div>
            }
        </Card>
    );
};

export default SettlementConceptSlider;
