import MatchingService from '../../shared/Matching/MatchingService';
import Property from '../../shared/Entity/Property/Property';
import PropertySlider from '../../shared/Property/PropertySlider';
import MatchingOverview from '../../shared/Matching/MatchingOverview/MatchingOverview';
import ReaBanner2024 from '../../user/ReaBanner2024/ReaBanner2024';
import WebinarBanner from '../../user/WebinarBanner/WebinarBanner';
import Sidebar from '../../../components/Sidebar';
import {useAppDispatch} from '../../../app/hooks';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

interface DashboardPageProps {
    readonly matchingService: MatchingService;
    readonly properties: Property[];
}

const DashboardPage = (props: DashboardPageProps): React.JSX.Element => {
    const [hasPropertiesWithContactIntentions, setHasPropertiesWithContactIntentions] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    useEffect((): void => {
        dispatch({
            type: 'breadcrumb/setBreadcrumbs',
            payload: []
        });
    }, [dispatch]);

    useEffect((): void => {
        setHasPropertiesWithContactIntentions(
            props.properties.find((property: Property): boolean => {
                return property.active === true && property.hasContactIntentions === true;
            }) !== undefined
        );
    }, [props.properties]);

    return (
        <div className="dashboard container-fluid">
            <div className="row p-1 p-md-4">
                <div className="col-md-9 col-lg-10">
                    <ReaBanner2024 />
                    <WebinarBanner />
                    <div className="row g-lg-5 g-3">
                        <div className="col-12 p-0 px-md-2 mb-4">
                            <p className="title ls-1 fw-semibold">Meine Flächen/Ladenlokale</p>
                            <PropertySlider properties={props.properties} perView={2} />
                            {props.properties.length > 0 &&
                                <Link to="/flaechen" className="btn btn-outline-secondary mx-auto my-4 w-100">Alle Flächen</Link>
                            }
                            {props.properties.length === 0 &&
                                <Link to="/flaechen/anbieten" className="btn btn-outline-secondary mx-auto my-4 w-100">Ladenlokal anlegen</Link>
                            }
                        </div>
                    </div>
                    {hasPropertiesWithContactIntentions === true &&
                        <div className="mb-3">
                            <MatchingOverview
                                matchingService={props.matchingService}
                                settlementConcepts={[]}
                                properties={props.properties}
                            />
                        </div>
                    }
                </div>
                <div className="col-md-3 col-lg-2">
                    <Sidebar
                        sidebarLinks={[
                            {icon: <i className="bi bi-shop fs-xl mt-3 text-secondary"></i>, title: 'Fläche / Ladenlokal anlegen', to: '/flaechen/anbieten', header: 'Nachnutzer finden:'},
                            {icon: <><i className="bi bi-bag-heart fs-xl mt-3 text-secondary"></i><sup className="text-secondary mb-3">beta</sup></>, title: 'Marktplatz', to: '/marktplatz'},
                            {icon: <i className="bi bi-magic fs-xl mt-3 text-secondary"></i>, title: 'Hall of Inspiration', to: '/hall-of-inspiration/konzepte'},
                            {icon: <i className="bi bi-person fs-xl mt-3 text-secondary"></i>, title: 'Ansprechpartner', to: '/ansprechpartner', ellipsis: true},
                            {icon: <i className="bi bi-book fs-xl mt-3 text-secondary"></i>, title: 'Glossar', to: 'https://www.llasm.de/glossar'},
                            {icon: <i className="bi bi-lightbulb fs-xl mt-3 text-secondary"></i>, title: 'FAQ', to: 'https://www.llasm.de/faq'},
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
