import fancyNumberInputFieldStyle from './FancyNumberInputField.module.scss';
import React, {useRef, useState} from 'react';

interface FancyNumberInputFieldProps {
    readonly amount: number | undefined;
    readonly setAmount: (amount: number | undefined | ((prevState: number | undefined) => number | undefined)) => void;
    readonly min: number;
    readonly max: number;
}

let timeout: NodeJS.Timeout | number | undefined = undefined;
let interval: NodeJS.Timeout | number | undefined = undefined;

const FancyNumberInputField = (props: FancyNumberInputFieldProps): React.JSX.Element => {
    const numberInputRef: React.MutableRefObject<HTMLInputElement | null> = useRef<HTMLInputElement | null>(null);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const buildClassName = (): string => {
        let className = fancyNumberInputFieldStyle.fancyNumberInputField;

        if (isFocused === true) {
            className += ' ' + fancyNumberInputFieldStyle.fancyNumberInputFieldFocused;
        }

        return className;
    };

    const count = (prevState: number |undefined, direction: 'up' | 'down'): number => {
        if (prevState === undefined) {
            return 1;
        }

        let result = prevState;

        if (direction === 'up') {
            result += 1;
        }

        if (direction === 'down') {
            result -= 1;
        }

        if (result < props.min || result > props.max) {
            return prevState;
        }

        return result;
    };

    const countUp = (): void => {
        setIsFocused(true);

        props.setAmount((prevState:  number | undefined): number => count(prevState, 'up'));

         timeout = setTimeout((): void => {
            stepUpInterval();
        }, 200);
    };

    const countDown = (): void => {
        setIsFocused(true);

        props.setAmount((prevState:  number | undefined): number => count(prevState, 'down'));

        timeout = setTimeout((): void => {
            stepDownInterval();
        }, 200);
    };

    const cancelCount = (): void => {
        if (timeout !== undefined) {
            clearTimeout(timeout);
        }

        if (interval !== undefined) {
            clearInterval(interval);
        }
    };

    const stepUpInterval = (): void => {
        interval = setInterval((): void => {
            props.setAmount((prevState:  number | undefined): number => count(prevState, 'up'));
        }, 100);
    };

    const stepDownInterval = (): void => {
        interval = setInterval((): void => {
            props.setAmount((prevState:  number | undefined): number => count(prevState, 'down'));
        }, 100);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.value === '') {
            props.setAmount(undefined);
        } else {
            if (Number(event.target.value) >= props.min && Number(event.target.value) <= props.max) {
                props.setAmount(Number(event.target.value));
            }
        }
    };

    return (
        <>
            <span onMouseDown={countDown} onMouseUp={cancelCount} onMouseOut={cancelCount} className="bi bi-dash-circle fs-2" />
            <input ref={numberInputRef} className={buildClassName()} value={props.amount} type="number" onChange={onChange} min={props.min} max={props.max} />
            <span onMouseDown={countUp} onMouseUp={cancelCount} onMouseOut={cancelCount} className="bi bi-plus-circle fs-2" />
        </>
    );
};

export default FancyNumberInputField;
