import MatchingResultStatusEntity, {getMatchingResultStatusLabel} from '../../Entity/Matching/MatchingResultStatus';
import MatchingResultGroup from '../../Entity/Matching/MatchingResultGroup';
import React from 'react';

interface MatchingResultStatusSummaryProps {
    readonly matchingResultGroup: MatchingResultGroup;
}

const MatchingResultStatusSummary = (props: MatchingResultStatusSummaryProps): React.JSX.Element => {
    return (
        <>
            <span className="ms-1 me-1">
                (
            </span>
            {props.matchingResultGroup.amountOfNewlyCreated > 0 &&
                <span className="text-white bg-secondary px-1 text-nowrap">
                    {props.matchingResultGroup.amountOfNewlyCreated + ' ' + getMatchingResultStatusLabel(MatchingResultStatusEntity.NewlyCreated)}
                </span>
            }
            {props.matchingResultGroup.amountOfRecentlyUpdated > 0 &&
                <>
                    {props.matchingResultGroup.amountOfNewlyCreated > 0 &&
                        <span> </span>
                    }
                    <span className="bg-dark-subtle px-1 text-nowrap">
                        {
                            props.matchingResultGroup.amountOfRecentlyUpdated
                            + ' '
                            + getMatchingResultStatusLabel(MatchingResultStatusEntity.RecentlyUpdated)
                        }
                    </span>
                </>
            }
            {props.matchingResultGroup.amountOfNewlyFound > 0 &&
                <>
                    {(props.matchingResultGroup.amountOfNewlyCreated > 0 || props.matchingResultGroup.amountOfRecentlyUpdated > 0) &&
                        <span> </span>
                    }
                    <span className="text-white bg-primary px-1 text-nowrap">
                        {props.matchingResultGroup.amountOfNewlyFound + ' ' + getMatchingResultStatusLabel(MatchingResultStatusEntity.NewlyFound)}
                    </span>
                </>
            }
            <span className="ms-1">
                )
            </span>
        </>
    );
};

export default MatchingResultStatusSummary;
