import Property from '../../Entity/Property/Property';
import TimeAvailability, {getTimeAvailabilityLabel} from '../../Entity/Property/TimeAvailability';
import PropertyAcquisitionType, {getPropertyAcquisitionTypeLabel} from '../../Entity/PropertyAcquisitionType';
import CollapseCard from '../../Component/CollapseCard/CollapseCard';
import LabelValuesPair from '../../../../components/LabelValuesPair';
import LabelValuePair from '../../../../components/LabelValuePair';
import React from 'react';

interface OfferDetailCollapseCardProps {
    readonly property: Property;
}

const OfferDetailCollapseCard = (props: OfferDetailCollapseCardProps): React.JSX.Element => {
    const propertyAcquisitionTypeValues: string[] = props.property.offerDetail.propertyAcquisitionTypes.map((propertyAcquisitionType: PropertyAcquisitionType): string => {
        return getPropertyAcquisitionTypeLabel(propertyAcquisitionType);
    });

    return (
        <CollapseCard cardType="shadow" title="Angebotsdaten" className="mb-3">
            <div className="row">
                {props.property.offerDetail.propertyAcquisitionTypes.length > 0 &&
                    <div className="col-12 col-lg-4 mb-3">
                        <LabelValuesPair label="Angebotsart(en)" values={propertyAcquisitionTypeValues} delimiter=", " />
                    </div>
                }
            </div>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                    {props.property.offerDetail.fromTimeAvailability === TimeAvailability.CertainDate && props.property.offerDetail.availableFrom !== null &&
                        <>
                            {(props.property.offerDetail.availableFrom < new Date() && (props.property.offerDetail.availableUntil === null || props.property.offerDetail.availableUntil >= new Date())) ? (
                                <LabelValuePair label="Verfügbar ab" value="sofort" />
                            ) : (
                                <LabelValuePair
                                    label="Verfügbar ab"
                                    value={props.property.offerDetail.availableFrom.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric'})}
                                />
                            )}
                        </>
                    }
                    {props.property.offerDetail.fromTimeAvailability !== TimeAvailability.CertainDate &&
                        <LabelValuePair label="Verfügbar ab" value={getTimeAvailabilityLabel(props.property.offerDetail.fromTimeAvailability)} />
                    }
                </div>
                {props.property.offerDetail.untilTimeAvailability !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        {props.property.offerDetail.untilTimeAvailability === TimeAvailability.CertainDate && props.property.offerDetail.availableUntil !== null &&
                            <LabelValuePair
                                label="Verfügbar bis"
                                value={props.property.offerDetail.availableUntil.toLocaleDateString('de-DE', {year: 'numeric', month: 'numeric', day: 'numeric'})}
                            />
                        }
                        {props.property.offerDetail.untilTimeAvailability !== TimeAvailability.CertainDate &&
                            <LabelValuePair
                                label="Verfügbar bis"
                                value={getTimeAvailabilityLabel(props.property.offerDetail.untilTimeAvailability)}
                            />
                        }
                    </div>
                }
            </div>
            <div className="row d-flex align-items-end">
                {(
                    props.property.offerDetail.purchaseDetail.purchasePrice !== null
                    && props.property.offerDetail.purchaseDetail.purchasePrice > 0
                    && props.property.offerDetail.propertyAcquisitionTypes.includes(PropertyAcquisitionType.Buy)
                ) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair
                            label="Kaufpreis"
                            value={new Intl.NumberFormat('de-DE').format(props.property.offerDetail.purchaseDetail.purchasePrice) + ' €'}
                        />
                    </div>
                }
                {props.property.offerDetail.purchaseDetail.commissionAmount !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Käuferprovision" value={props.property.offerDetail.purchaseDetail.commissionAmount} />
                    </div>
                }
                {props.property.offerDetail.purchaseDetail.commissionNote !== null &&
                    <div className="col-12 col-lg-4 mb-3">
                        <LabelValuePair label="Provision (Erläuterung)" value={props.property.offerDetail.purchaseDetail.commissionNote} />
                    </div>
                }
            </div>
            <div className="row d-flex align-items-end">
                {(props.property.offerDetail.rentalDetail.netRentalPrice !== null && props.property.offerDetail.rentalDetail.netRentalPrice > 0) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Miete/Pacht (netto) / Monat" value={new Intl.NumberFormat('de-DE').format(props.property.offerDetail.rentalDetail.netRentalPrice) + ' €'} />
                    </div>
                }
                {(props.property.offerDetail.rentalDetail.runningCosts !== null && props.property.offerDetail.rentalDetail.runningCosts > 0) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Nebenkosten inkl. Heizkosten / Monat" value={new Intl.NumberFormat('de-DE').format(props.property.offerDetail.rentalDetail.runningCosts) + ' €'} />
                    </div>
                }
                {props.property.offerDetail.rentalDetail.deposit !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Kaution" value={props.property.offerDetail.rentalDetail.deposit} />
                    </div>
                }
                {(props.property.offerDetail.rentalDetail.dailyRent !== null && props.property.offerDetail.rentalDetail.dailyRent > 0) &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Tagesmiete (netto)" value={new Intl.NumberFormat('de-DE').format(props.property.offerDetail.rentalDetail.dailyRent) + ' €'} />
                    </div>
                }
                {props.property.offerDetail.rentalDetail.commissionAmount !== null &&
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Provision bei Mietvertragsabschluss" value={props.property.offerDetail.rentalDetail.commissionAmount} />
                    </div>
                }
                {props.property.offerDetail.rentalDetail.commissionNote !== null &&
                    <div className="col-12 col-lg-4 mb-3">
                        <LabelValuePair label="Provision (Erläuterung)" value={props.property.offerDetail.rentalDetail.commissionNote} />
                    </div>
                }
            </div>
            <div className="row">
                {props.property.offerDetail.otherDetails !== null &&
                    <div className="col-12 mb-3">
                        <LabelValuePair label="Sonstige Angaben zum Angebot" value={props.property.offerDetail.otherDetails} />
                    </div>
                }
            </div>
            {props.property.offerDetail.fundingAvailable === true &&
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <LabelValuePair label="Förderung vorhanden" value="ja" />
                    </div>
                    {props.property.offerDetail.fundedBy !== null &&
                        <div className="col-12 col-md-6 col-lg-8 mb-3">
                            <LabelValuePair label="Gefördert durch" value={props.property.offerDetail.fundedBy} />
                        </div>
                    }
                    {props.property.offerDetail.fundingCondition !== null &&
                        <div className="col-12 mb-3">
                            <LabelValuePair label="Förderbedingungegen/ Fördergegenstand" value={props.property.offerDetail.fundingCondition} />
                        </div>
                    }
                </div>
            }
        </CollapseCard>
    );
};

export default OfferDetailCollapseCard;
