import PaymentService from '../../shared/Payment/PaymentService';
import OrderStep from '../../shared/Entity/MatchPoint/OrderStep';
import User from '../../shared/Entity/User/User';
import Currency from '../../shared/Entity/Currency';
import MatchPointCard from './MatchPointCard';
import Price from './Price';
import InfoButtonOverlay from '../../shared/Component/InfoButtonOverlay/InfoButtonOverlay';
import FancyNumberInputField from '../../shared/Component/FancyNumberInputField/FancyNumberInputField';
import Tooltip from '../../shared/Component/Tooltip/Tooltip';
import React from 'react';
import {Link} from 'react-router-dom';

interface MatchPointSelectionProps {
    readonly paymentService: PaymentService;
    readonly setOrderStep: (orderStep: OrderStep) => void;
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly amount: number | undefined;
    readonly setAmount: (amount: number | undefined | ((prevState: number | undefined) => number | undefined)) => void;
    readonly priceNet: number;
    readonly priceGross: number;
}

const MatchPointSelection = (props: MatchPointSelectionProps): React.JSX.Element => {
    return (
        <div className="container-fluid pb-4">
            <div className="d-flex align-items-center mb-3">
                <Link to="" onClick={(): void => window.history.back()}>
                    <i className="bi bi-arrow-left-short text-secondary fs-lg"></i>
                </Link>
                <h1 className="text-secondary fs-3 px-3 m-0 mb-md-1">MatchPoints</h1>
                <InfoButtonOverlay title="MatchPoints" className="fs-5 me-3 ms-auto">
                    <div className="mb-3">
                        Hier findest Du eine Übersicht Deiner MatchPoints, kannst neue erwerben oder Dir ein Angebot für eine Flatrate erstellen lassen.
                        Denk dran, dass du zum Freischalten der Matches immer genügend MatchPoints hast.
                    </div>
                </InfoButtonOverlay>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 mb-3">
                    <MatchPointCard header="Deine aktuellen MatchPoints" description="Deine aktuelle Anzahl an MatchPoints findest Du an dieser Stelle.">
                        <div className="d-flex justify-content-center align-items-center text-primary py-5 fs-1">
                            {props.user.numberOfMatchPoints}
                        </div>
                    </MatchPointCard>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <MatchPointCard header="MatchPoints kaufen" description="Du benötigst mehr MatchPoints? Hier kannst Du diese kaufen.">
                        {props.paymentService.canDoPayment(props.user) === false &&
                            <>
                                {props.user.isCommercial() === false &&
                                    <div className="fs-5 text-center">
                                        Du hast angegeben, dass Du außerhalb von Deutschland wohnst. Bitte kontaktiere uns<br />
                                        unter <a href="mailto:payment@llasm.de">payment@llasm.de</a> oder<br />
                                        Tel.: +49 2171 40 188 40 um MatchPoints zu erwerben.
                                    </div>
                                }
                                {props.user.isCommercial() === true &&
                                    <div className="fs-5 text-center">
                                        Du hast angegeben, dass Dein Firmensitz außerhalb der EU liegt.
                                        Bitte kontaktiere uns unter <a href="mailto:payment@llasm.de">payment@llasm.de</a> oder<br/>
                                        Tel.: +49 2171 40 188 40 um MatchPoints zu erwerben.
                                    </div>
                                }
                            </>
                        }
                        {props.paymentService.canDoPayment(props.user) === true &&
                            <>
                                <div className="d-flex justify-content-center mb-3">
                                    <FancyNumberInputField amount={props.amount} setAmount={props.setAmount} min={1} max={2500} />
                                </div>
                                {props.amount === undefined &&
                                    <div className="text-danger text-center">
                                        Bitte gib an wieviele MatchPoints du kaufen möchtest.
                                    </div>
                                }
                                {props.amount !== undefined &&
                                    <Price
                                        user={props.user}
                                        numberFormat={props.numberFormat}
                                        currency={props.currency}
                                        amount={props.amount}
                                        priceNet={props.priceNet}
                                        priceGross={props.priceGross}
                                        showPricePerMatchPoint={true}
                                    />
                                }
                                {props.priceNet > 0 && props.priceGross > 0 &&
                                    <div className="text-center mt-3">
                                        <button
                                            className="btn btn-primary fs-4"
                                            onClick={(): void => props.setOrderStep(OrderStep.OrderOverview)}
                                            disabled={props.amount === undefined}
                                        >
                                            Weiter zur Buchung
                                        </button>
                                    </div>
                                }
                            </>
                        }
                    </MatchPointCard>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <MatchPointCard
                        header="Flatrate"
                        description="Du möchtest grenzenlos matchen? Dann entscheide Dich am besten für eine Flatrate. Hierfür erstellen wir Dir auf Wunsch gerne ein Angebot, damit Du unbegrenzten Zugriff auf alle Matches hast 😊."
                    >
                        <div className="text-center text-primary fs-1 py-5">
                            Auf Anfrage
                        </div>
                        <div className="d-flex justify-content-center gap-3 fs-1">
                            <Tooltip title="Tel.: 02171 4018846">
                                <a href="tel:+4921714018846"><i className="bi bi-telephone" style={{color: '#000000'}}></i></a>
                            </Tooltip>
                            <Tooltip title="E-Mail: hallo@llasm.de">
                                <a href="mailto:hallo@llasm.de"><i className="bi bi-envelope" style={{color: '#000000'}}></i></a>
                            </Tooltip>
                        </div>
                    </MatchPointCard>
                </div>
            </div>
        </div>
    );
};

export default MatchPointSelection;
