import Property from '../Entity/Property/Property';
import Card from '../Component/Card/Card';
import CardContent from '../Component/Card/CardContent';
import React from 'react';

interface InitialPropertyCreatedMessageProps {
    readonly property: Property;
}

const InitialPropertyCreatedMessage = (props: InitialPropertyCreatedMessageProps): React.JSX.Element => {
    return (
        <Card cardType="outline" className="mb-5">
            <CardContent>
                {props.property.active === true &&
                    <>
                        <div className="fs-5 fw-bold mb-2">
                            Glückwunsch: Du hast Deine erste Fläche eingestellt und aktiviert.
                        </div>
                        <div className="mb-1">
                            Du kannst die Fläche jetzt entweder mit passenden Gesuchen matchen oder in unserem Marktplatz veröffentlichen.
                        </div>
                        <div className="mb-1">
                            Um herauszufinden, welche spannenden Nutzungskonzepte unser Matching-Algorithmus für Dich findet, klicke einfach auf den
                            Button „Let‘s Match“. Deine Fläche ist erst dann für suchende Unternehmen sichtbar, wenn Du bei einem Konzept auf „Gefällt
                            mir“ klickst.
                        </div>
                        <div>
                            Wenn Du die Fläche in unserem  <a href="/marktplatz">Marktplatz</a> veröffentlichen willst, sodass sie für alle Nutzer
                            sichtbar ist und Dich Interessenten kontaktieren können, musst Du sie mit einem Stellplatz im Marktplatz veröffentlichen.
                            Gehe dazu im Menü oben rechts auf Marktplatz und dann auf den Button „Meine Inserate“.
                        </div>
                    </>
                }
                {props.property.active === false &&
                    <>
                        <div className="fs-5 fw-bold mb-2">
                            Du hast Deine erste Fläche eingestellt – sie ist noch inaktiv.
                        </div>
                        <div className="mb-1">
                            Herzlichen Glückwunsch – Du hast Deine erste Fläche eingestellt. Sie ist allerdings noch deaktiviert. Sobald Du sie
                            aktivierst, kannst Du die Fläche entweder mit passenden Gesuchen matchen oder in unserem Marktplatz veröffentlichen.
                        </div>
                        <div className="mb-1">
                            Um herauszufinden, welche spannenden Nutzungskonzepte unser Matching-Algorithmus für Dich findet, klicke nach dem
                            Aktivieren einfach auf den Button „Let‘s Match“. Deine Fläche ist erst dann für suchende Unternehmen sichtbar, wenn Du bei
                            einem Konzept auf „Gefällt mir“ klickst.
                        </div>
                        <div>
                            Wenn Du die Fläche in unserem <a href="/marktplatz">Marktplatz</a> veröffentlichen willst, sodass sie für alle Nutzer
                            sichtbar ist und Dich Interessenten kontaktieren können, musst Du sie mit einem Stellplatz im Marktplatz verknüpfen.
                            Gehe dazu im Menü oben rechts auf Marktplatz und dann auf den Button „Meine Inserate“.
                        </div>
                    </>
                }
            </CardContent>
        </Card>
    );
};

export default InitialPropertyCreatedMessage;
