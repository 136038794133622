import IndustryClassificationResponse from '../../../../api/Llasm/IndustryClassification/IndustryClassificationResponse';

class IndustryClassification {
    private _id?: number;

    private _uniqueKey: number;

    private _name: string;

    private _levelOne: number;

    private _levelTwo: number | null = null;

    private _levelThree: number | null = null;

    public static createFromIndustryClassificationResponse(industryClassificationResponse: IndustryClassificationResponse): IndustryClassification {
        const industryClassification: IndustryClassification = new this();

        industryClassification._id = industryClassificationResponse.id;
        industryClassification._name = industryClassificationResponse.name;
        industryClassification._levelOne = industryClassificationResponse.levelOne;
        industryClassification._levelTwo = industryClassificationResponse.levelTwo;
        industryClassification._levelThree = industryClassificationResponse.levelThree;

        return industryClassification;
    }

    public constructor() {
        this._uniqueKey = Math.random();
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get uniqueKey(): number {
        return this._uniqueKey;
    }

    set uniqueKey(value: number) {
        this._uniqueKey = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get levelOne(): number {
        return this._levelOne;
    }

    set levelOne(value: number) {
        this._levelOne = value;
    }

    get levelTwo(): number | null {
        return this._levelTwo;
    }

    set levelTwo(value: number | null) {
        this._levelTwo = value;
    }

    get levelThree(): number | null {
        return this._levelThree;
    }

    set levelThree(value: number | null) {
        this._levelThree = value;
    }

}

export default IndustryClassification;
