import User from '../../shared/Entity/User/User';
import Currency, {getCurrencySymbol} from '../../shared/Entity/Currency';
import BillingAddress from '../../shared/Entity/User/BillingAddress';
import React from 'react';

interface OrderOverviewInformationProps {
    readonly user: User;
    readonly numberFormat: Intl.NumberFormat;
    readonly currency: Currency;
    readonly taxRate: number;
    readonly amount: number;
    readonly priceNet: number;
    readonly priceGross: number;
    readonly billingAddress: BillingAddress;
    readonly paymentMethodText?: string;
}

const OrderOverviewInformation = (props: OrderOverviewInformationProps): React.JSX.Element => {
    return (
        <>
            <div className="mb-3">
                <div className="d-flex">
                    <div className="fw-bold">Anzahl MatchPoints:</div>
                    <div className="ms-auto">{props.amount}</div>
                </div>
                <div className="d-flex">
                    <div className="fw-bold">Bestellsumme (netto):</div>
                    <div className="ms-auto">{getCurrencySymbol(props.currency)} {props.numberFormat.format(props.priceNet)}</div>
                </div>
                <div className="d-flex">
                    <div className="fw-bold">Lieferkosten:</div>
                    <div className="ms-auto">{getCurrencySymbol(props.currency)} {props.numberFormat.format(0)}</div>
                </div>

                {props.user.isFromGermany() === true &&
                    <div className="d-flex">
                        <div className="fw-bold">Zwischensumme (netto):</div>
                        <div className="ms-auto">{getCurrencySymbol(props.currency)} {props.numberFormat.format(props.priceNet)}</div>
                    </div>
                }

                {props.user.isFromGermany() === false && props.user.isFromEuropeanUnionMember() === true &&
                    <div className="d-flex">
                        <div className="fw-bold">Gesamtsumme (netto):</div>
                        <div className="ms-auto">{getCurrencySymbol(props.currency)} {props.numberFormat.format(props.priceNet)}</div>
                    </div>
                }

                <div className="d-flex">
                    <div className="fw-bold">Mehrwertsteuer ({props.taxRate}%):</div>
                    <div className="ms-auto">{getCurrencySymbol(props.currency)} {props.numberFormat.format((props.priceGross - props.priceNet))}</div>
                </div>

                {props.user.isFromGermany() === true &&
                    <div className="d-flex">
                        <div className="fw-bold">Gesamtsumme:</div>
                        <div className="ms-auto">{getCurrencySymbol(props.currency)} {props.numberFormat.format(props.priceGross)}</div>
                    </div>
                }

                {props.user.isFromGermany() === false && props.user.isFromEuropeanUnionMember() === true &&
                    <div className="pt-3 pb-3">
                        Umkehr der Steuerschuld
                    </div>
                }
            </div>
            <div>
                <div className="fw-bold">Rechnungsadresse:</div>
                {props.user.naturalPerson === false &&
                    <div>{props.billingAddress.companyName}</div>
                }
                <div>{props.billingAddress.firstName} {props.billingAddress.lastName}</div>
                <div>{props.billingAddress.streetName} {props.billingAddress.houseNumber}</div>
                <div>{props.billingAddress.postalCode} {props.billingAddress.placeName}</div>
                <div>{props.user.country.countryName}</div>
                <div>{props.billingAddress.email}</div>
            </div>
            {props.paymentMethodText !== undefined &&
                <div className="mt-3">
                    <div className="fw-bold">Ausgewählte Zahlart:</div>
                    <div>{props.paymentMethodText}</div>
                </div>
            }
        </>
    );
};

export default OrderOverviewInformation;
