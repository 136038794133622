import UserService from './UserService';
import User from '../Entity/User/User';
import UserEmailChange from '../Entity/User/UserEmailChange';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import FormData from '../../shared/Entity/Form/FormData';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import EmailValidationDefinition from '../FormValidationHandler/EmailValidationDefinition';
import RepetitionValidationDefinition from '../FormValidationHandler/RepetitionValidationDefinition';
import InputField from '../Component/Form/Field/InputField';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import React, {ChangeEvent, useState} from 'react';

interface UserEmailChangeFormProps {
    readonly user: User;
}

const successAlert: Alert = new Alert(AlertType.Success, 'Deine Anfrage zur Änderung der E-Mail-Adresse war erfolgreich. Bitte überprüfe Dein E-Mail-Postfach um die Änderung zu verifizieren.');

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const emailAlreadyRegisteredAlert: Alert = new Alert(AlertType.Error, 'Die angegebene E-Mail-Adresse ist leider schon vergeben.')

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Bitte fülle alle rot markierten Pflichtfelder aus.');

const fieldValidationDefinitions: FieldValidationDefinition<UserEmailChange>[] = [
    new RequiredValidationDefinition<UserEmailChange>('newEmail', 'E-Mail muss ausgefüllt sein.'),
    new EmailValidationDefinition<UserEmailChange>('newEmail', 'Das E-Mail Format ist nicht korrekt'),
    new RepetitionValidationDefinition<UserEmailChange>('repeatEmail', 'newEmail', 'Die eingegebenen E-Mail-Adressen stimmen nicht überein.'),
];

const formValidationHandler: FormValidationHandler<UserEmailChange> = new FormValidationHandler<UserEmailChange>(fieldValidationDefinitions);

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const UserEmailChangeForm = (props: UserEmailChangeFormProps): React.JSX.Element => {
    const [userEmailChange, setUserEmailChange] = useState<UserEmailChange>(new UserEmailChange());

    const [formData, setFormData] = useState<FormData<UserEmailChange>>({data: userEmailChange!});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const persistUserEmailChange = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setIsLoading(true);

        try {
            await userService.persistUserEmailChange(formData.data);

            resetForm();
            setAlert(successAlert);
        } catch (error: any) {
            if (error.status === 409) {
                setAlert(emailAlreadyRegisteredAlert);
            } else {
                setAlert(errorAlert);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (userEmailChange as any)[event.target.name] = event.target.value;

        setUserEmailChange(userEmailChange);
        updateFormData();
        validateField(event.target.name);
    };

    const validateField = (fieldName: string): void => {
        formValidationHandler.validateField(fieldName, formData);

        setFormData({...formData, errors: formData.errors});
    };

    const updateFormData = (): void => {
        setFormData({...formData, data: userEmailChange});
    };

    const resetForm = (): void => {
        userEmailChange.newEmail = '';
        userEmailChange.repeatEmail = '';

        setUserEmailChange(userEmailChange);
        updateFormData();
    };

    return (
        <>
            {isLoading === true &&
                <Spinner />
            }
            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <InputField
                        label="Aktuelle E-Mail"
                        name="email"
                        required={true}
                        type="email"
                        value={props.user.email}
                        disabled={true}
                    />
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <InputField
                        label="Neue E-Mail"
                        name="newEmail"
                        required={true}
                        type="email"
                        value={userEmailChange.newEmail}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'newEmail')}
                    />
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <InputField
                        label="E-Mail wiederholen"
                        name="repeatEmail"
                        required={true}
                        type="email"
                        value={userEmailChange.repeatEmail}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'repeatEmail')}
                    />
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <button
                        className="btn btn-primary w-100 mt-md-4" type="submit"
                        onClick={persistUserEmailChange}
                        disabled={formValidationHandler.hasErrors(formData) === true}
                    >
                        E-Mail ändern
                    </button>
                </div>
            </div>
            {alert !== undefined &&
                <div className="mt-3">
                    <AlertBox alert={alert} autoDismiss={false} />
                </div>
            }
        </>
    );
};

export default UserEmailChangeForm;
