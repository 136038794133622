import FormData from '../Entity/Form/FormData';
import Detail from '../Entity/Property/Detail';
import SpecialObjectCategory, {getSpecialObjectCategoryLabel} from '../Entity/Property/SpecialObjectCategory';
import SelectOption from '../Entity/Form/SelectOption';
import SpecialObjectSeparation, {getSpecialObjectSeparationLabel} from '../Entity/Property/SpecialObjectSeparation';
import AccessibilityType, {getAccessibilityTypeLabel} from '../Entity/AccessibilityType';
import LastUsage, {getLastUsageLabel} from '../Entity/Property/LastUsage';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import Tooltip from '../Component/Tooltip/Tooltip';
import CheckboxField from '../Component/Form/Field/CheckboxField';
import MultiSelectField from '../Component/Form/Field/MultiSelectField';
import InputField from '../Component/Form/Field/InputField';
import SelectField from '../Component/Form/Field/SelectField';
import SwitchField from '../Component/Form/Field/SwitchField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import React, {useEffect} from 'react';

interface DetailFormProps {
    readonly formData: FormData<Detail>;
    readonly setFormData: (formData: FormData<Detail>) => void;
    readonly validationDefinitions: FieldValidationDefinition<Detail>[];
}

{/*ToDo: Enum...? */}
const floorsSelectOptions: SelectOption<string>[] = [
    {label: '2. Untergeschoss', value: '2. UG'},
    {label: '1. Untergeschoss', value: '1. UG'},
    {label: 'Erdgeschoss', value: 'EG'},
    {label: '1. Obergeschoss', value: '1. OG'},
    {label: '2. Obergeschoss', value: '2. OG'},
    {label: '3. Obergeschoss', value: '3. OG'},
    {label: 'weitere Etagen', value: 'weitere'},
];

const specialObjectSeparationOptions: SelectOption<SpecialObjectSeparation>[] = [
    {label: getSpecialObjectSeparationLabel(SpecialObjectSeparation.TemporalSeparation), value: SpecialObjectSeparation.TemporalSeparation},
    {label: getSpecialObjectSeparationLabel(SpecialObjectSeparation.SpatialSeparation), value: SpecialObjectSeparation.SpatialSeparation},
];

const accessibilityTypeSelectOptions: SelectOption<AccessibilityType>[] = [
    {label: getAccessibilityTypeLabel(AccessibilityType.Available), value: AccessibilityType.Available},
    {label: getAccessibilityTypeLabel(AccessibilityType.CanBeGuaranteed), value: AccessibilityType.CanBeGuaranteed},
    {label: getAccessibilityTypeLabel(AccessibilityType.CurrentlyNotAvailable), value: AccessibilityType.CurrentlyNotAvailable},
    {label: getAccessibilityTypeLabel(AccessibilityType.NotPossible), value: AccessibilityType.NotPossible},
];

const lastUsageSelectOptions: SelectOption<LastUsage | null>[] = [
    {label: 'Bitte wählen', value: null},
    {label: getLastUsageLabel(LastUsage.Retail), value: LastUsage.Retail},
    {label: getLastUsageLabel(LastUsage.Gastronomy), value: LastUsage.Gastronomy},
    {label: getLastUsageLabel(LastUsage.Service), value: LastUsage.Service},
    {label: getLastUsageLabel(LastUsage.ArtCultureLeisure), value: LastUsage.ArtCultureLeisure},
    {label: getLastUsageLabel(LastUsage.Other), value: LastUsage.Other},
];

const DetailForm = (props: DetailFormProps): React.JSX.Element => {
    const detail: Detail = props.formData.data;

    useEffect((): void => {
        props.setFormData({...props.formData, formValidationHandler: new FormValidationHandler<Detail>(props.validationDefinitions)});
    }, [props.validationDefinitions]);

    useEffect((): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        if (detail.specialObjectCategories.includes(SpecialObjectCategory.MixedUseArea) === true) {
            props.formData.formValidationHandler.addFieldValidationDefinition(
                new RequiredValidationDefinition<Detail>('specialObjectSeparations', 'Zeitliche/Räumliche Trennung  muss gewählt sein.')
            );
        } else {
            if (props.formData.formValidationHandler !== undefined) {
                props.formData.formValidationHandler.removeFieldValidationDefinitionsByFieldName('specialObjectSeparations');

                detail.specialObjectSeparations = [];

                updateFormData();
            }
        }
    }, [detail.specialObjectCategories.length]);

    const fetchFloorsSelectedOptions = (): SelectOption<string>[] => {
        if (detail.floors.length === 0) {
            return [];
        }

        return floorsSelectOptions.filter((selectOption: SelectOption<string>): boolean => {
            return detail.floors.includes(selectOption.value);
        });
    };

    const fetchSpecialObjectSeparationSelectedOptions = (): SelectOption<SpecialObjectSeparation>[] => {
        if (detail.specialObjectSeparations.length === 0) {
            return [];
        }

        return specialObjectSeparationOptions.filter((selectOption: SelectOption<SpecialObjectSeparation>): boolean => {
            return detail.specialObjectSeparations.includes(selectOption.value);
        });
    };

    const fetchAccessibilityTypeSelectedOption = (): SelectOption<AccessibilityType> | undefined => {
        if (detail.accessibilityType === undefined) {
            return undefined;
        }

        return accessibilityTypeSelectOptions.find((selectOption: SelectOption<AccessibilityType>): boolean => {
            return detail.accessibilityType === selectOption.value;
        });
    };

    const fetchLastUsageSelectedOption = (): SelectOption<LastUsage | null> | undefined => {
        return lastUsageSelectOptions.find((selectOption: SelectOption<LastUsage | null>): boolean => {
            return detail.lastUsage === selectOption.value;
        });
    };

    const calculateTotalAreaSize = (): number => {
        let totalAreaSize: number = 0;

        if (detail.areaSize !== undefined) {
            totalAreaSize += detail.areaSize;
        }

        if (detail.secondaryAreaSize !== null) {
            totalAreaSize += detail.secondaryAreaSize;
        }

        return totalAreaSize;
    };

    const toggleSpecialObjectCategory = (specialObjectCategory: SpecialObjectCategory): void => {
        const specialObjectCategoryIndex: number = detail.specialObjectCategories.indexOf(specialObjectCategory);

        if (specialObjectCategoryIndex === -1) {
            detail.specialObjectCategories.push(specialObjectCategory);
        } else {
            detail.specialObjectCategories.splice(specialObjectCategoryIndex, 1);
        }

        updateFormData();
    };

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'areaSize':
                detail.areaSize = Number(event.target.value);

                break;
            case 'secondaryAreaSize':
                if (event.target.value === '') {
                    detail.secondaryAreaSize = null;
                } else {
                    detail.secondaryAreaSize = Number(event.target.value);
                }

                break;
            case 'outdoorSalesAreaSize':
                if (event.target.value === '') {
                    detail.outdoorSalesAreaSize = null;
                } else {
                    detail.outdoorSalesAreaSize = Number(event.target.value);
                }

                break;
            case 'storeWidth':
                if (event.target.value === '') {
                    detail.storeWidth = null;
                } else {
                    detail.storeWidth = Number(event.target.value);
                }

                break;
            case 'shopWindowFrontLength':
                if (event.target.value === '') {
                    detail.shopWindowFrontLength = null;
                } else {
                    detail.shopWindowFrontLength = Number(event.target.value);
                }

                break;
            case 'numberOfParkingLots':
                if (event.target.value === '') {
                    detail.numberOfParkingLots = null;
                } else {
                    detail.numberOfParkingLots = Number(event.target.value);
                }

                break;
            default:
                (detail as any)[event.target.name] = event.target.value;

                break;
        }

        updateFormData();
        validateField(event.target.name);
    };

    const handleFloorsChange = (selectedValues: readonly SelectOption<string>[]): void => {
        detail.floors = selectedValues.map((selectOption: SelectOption<string>): string => {
            return selectOption.value;
        });

        updateFormData();
        validateField('floors');
    };

    const handleSpecialObjectSeparationChange = (selectedValues: readonly SelectOption<SpecialObjectSeparation>[]): void => {
        detail.specialObjectSeparations = selectedValues.map((selectOption: SelectOption<SpecialObjectSeparation>): SpecialObjectSeparation => {
            return selectOption.value;
        });

        updateFormData();
        validateField('specialObjectSeparations');
    };

    const handleAccessibilityTypeChange = (selectedValue: SelectOption<AccessibilityType> | null): void => {
        if (selectedValue === null) {
            return;
        }

        detail.accessibilityType = selectedValue.value;

        updateFormData();
        validateField('accessibilityType');
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'groundLevelSalesArea') {
            detail.groundLevelSalesArea = event.target.checked;
        }

        if (event.target.name === 'parkingLotAvailable') {
            detail.parkingLotAvailable = event.target.checked;
        }

        updateFormData();
    };

    const handleLastUsageChange = (selectedValue: SelectOption<LastUsage | null> | null): void => {
        if (selectedValue === null) {
            return;
        }

        detail.lastUsage = selectedValue.value;

        updateFormData();
        validateField('lastUsage');
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: detail});
    };

    const validateField = (fieldName: string): void => {
        if (props.formData.formValidationHandler === undefined) {
            return;
        }

        props.formData.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    return (
        <>
            <div className="mb-2">
                Spezialobjekt
                <Tooltip
                    title="Die Angabe, ob es sich bei Deiner Fläche um ein Spezialobjekt handelt hilft uns, diese besser darzustellen und in das Matching zu integrieren. Ggf. werden weitere Felder freigeschaltet."
                    icon={<i className="bi bi-info-circle"></i>}
                    className="text-info ms-1"
                />
            </div>
            <div className="text-muted mb-3">Handelt es sich bei dem Objekt um eine oder mehrere der folgenden Spezialkategorien?</div>
            <div className="row">
                <div className="col-12 col-lg-3 mb-3">
                    <CheckboxField
                        checked={detail.specialObjectCategories.includes(SpecialObjectCategory.PopUpArea) === true}
                        name="popupSpace"
                        label={getSpecialObjectCategoryLabel(SpecialObjectCategory.PopUpArea)}
                        description="Damit sind Flächen gemeint, die nur für eine bestimmte Zeit (meistens 2-6 Monate) vergeben werden und dann wieder an neue Nachmieter vermittelt werden."
                        onChange={(): void => toggleSpecialObjectCategory(SpecialObjectCategory.PopUpArea)}
                    />
                </div>
                <div className="col-12 col-lg-3 mb-3">
                    <CheckboxField
                        checked={detail.specialObjectCategories.includes(SpecialObjectCategory.MixedUseArea) === true}
                        name="mixedUseSpace"
                        label={getSpecialObjectCategoryLabel(SpecialObjectCategory.MixedUseArea)}
                        description="Damit sind Flächen gemeint, die entweder räumlich oder zeitlich getrennt gemeinsam von mehreren Konzeptbetreibern genutzt werden."
                        onChange={(): void => toggleSpecialObjectCategory(SpecialObjectCategory.MixedUseArea)}
                    />
                </div>
                {/* TODO comment back in once requirements for CoWorkingSpace are clearer */}
                {/*<div className="col-12 col-lg-3 mb-3">*/}
                {/*    <CheckboxField*/}
                {/*        checked={detail.specialObjectCategories.includes(SpecialObjectCategory.CoWorkingSpace) === true}*/}
                {/*        name="coWorkingSpace"*/}
                {/*        label={getSpecialObjectCategoryLabel(SpecialObjectCategory.CoWorkingSpace)}*/}
                {/*        description="Damit sind Flächen gemeint, die die Möglichkeit bieten, Arbeitsplätze in Form eines Flex Desk zu buchen."*/}
                {/*        onChange={(): void => toggleSpecialObjectCategory(SpecialObjectCategory.CoWorkingSpace)}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className="col-12 col-lg-3 mb-3">
                    <CheckboxField
                        checked={detail.specialObjectCategories.includes(SpecialObjectCategory.DepartmentStore) === true}
                        name="departmentStore"
                        label={getSpecialObjectCategoryLabel(SpecialObjectCategory.DepartmentStore)}
                        description="Damit sind größere Objekte, wie z. B. ehemalige Kaufhäuser gemeint, die in der Regel kein einfaches Matching mit passenden Nachnutzern erlauben, da die Flächenaufteilung nicht für einen Nachnutzer geeignet ist."
                        onChange={(): void => toggleSpecialObjectCategory(SpecialObjectCategory.DepartmentStore)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 mb-lg-3">
                    <MultiSelectField
                        label="Etage(n)"
                        name="floors"
                        options={floorsSelectOptions}
                        required={true}
                        closeMenuOnSelect={false}
                        value={fetchFloorsSelectedOptions()}
                        placeholder="Bitte wählen"
                        onChange={handleFloorsChange}
                        description="Über welche Etage(n) erstreckt sich Deine Fläche? Mehrfachauswahl möglich."
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'floors')}
                    />
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    {detail.specialObjectCategories.includes(SpecialObjectCategory.MixedUseArea) === true &&
                        <MultiSelectField
                            label="Zeitliche/Räumliche Trennung"
                            name="specialObjectSeparations"
                            options={specialObjectSeparationOptions}
                            required={true}
                            closeMenuOnSelect={false}
                            value={fetchSpecialObjectSeparationSelectedOptions()}
                            placeholder="Bitte wählen"
                            onChange={handleSpecialObjectSeparationChange}
                            description="Deine Fläche ist für Mixed-Use Konzepte geeignet. Ist hierbei eine zeitliche Teilung der Fläche oder eine räumliche Teilung der Fläche möglich?"
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'specialObjectSeparations')}
                        />
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4 col-xxl-2 mb-3">
                    <InputField
                        name="areaSize"
                        label="(Verkaufs-)Fläche"
                        description="Bitte gib hier die (Verkaufs-)Fläche Deines Objekts ohne Nebenflächen an."
                        type="number"
                        suffix="m²"
                        min={0}
                        required={true}
                        value={detail.areaSize}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'areaSize')}
                    />
                </div>
                <div className="col-12 col-md-4 col-xxl-2 mb-3">
                    <InputField
                        name="secondaryAreaSize"
                        label="Nebenfläche"
                        description="Wie groß sind die Nebenflächen - z.B. Toiletten, Lagerräume, Kühlhäuser etc.?"
                        type="number"
                        suffix="m²"
                        min={0}
                        required={false}
                        value={detail.secondaryAreaSize ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12 col-md-4 col-xxl-2 mb-3">
                    <InputField
                        name="totalAreaSize"
                        label="Gesamtfläche"
                        description="Die Gesamtfläche berechnet sich automatisch aus (Verkaufs-)Fläche und Nebenfläche."
                        type="number"
                        suffix="m²"
                        min={0}
                        required={true}
                        value={calculateTotalAreaSize()}
                        disabled={true}
                    />
                </div>
                <div className="col-12 col-md-4 col-xxl-2 mb-3">
                    <InputField
                        name="outdoorSalesAreaSize"
                        label="Außenverkaufsfläche"
                        description="Deine Fläche bietet die Möglichkeit, Außenbereiche für Werbemaßnahmen oder zum Verkauf zu nutzen? Hier kannst Du eintragen, wie groß dieser Bereich ist."
                        type="number"
                        suffix="m²"
                        min={0}
                        required={false}
                        value={detail.outdoorSalesAreaSize ?? undefined}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12 col-md-4 col-xxl-2 mb-3">
                    <InputField
                        name="storeWidth"
                        label="Ladenbreite"
                        type="number"
                        min={0}
                        value={detail.storeWidth ?? undefined}
                        id="storeWidth"
                        suffix="m"
                        description="Wie breit ist die Frontseite Deiner Ladenfläche?"
                        onChange={handleChange}
                    />
                </div>
                <div className="col-12 col-md-4 col-xxl-2 mb-3">
                    <InputField
                        name="shopWindowFrontLength"
                        label="Schaufensterfront"
                        type="number"
                        min={0}
                        value={detail.shopWindowFrontLength ?? undefined}
                        suffix="m"
                        description="Wie breit ist die Schaufensterfront Deiner Fläche?"
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="row mb-3">
                        <div className="col-12">
                            <SelectField
                                name="accessibilityType"
                                label="Barrierefreier Zugang"
                                required={true}
                                options={accessibilityTypeSelectOptions}
                                value={fetchAccessibilityTypeSelectedOption()}
                                placeholder="Bitte wählen"
                                onChange={handleAccessibilityTypeChange}
                                description="Ist Deine Fläche barrierefrei? Ein Ladenlokal ist barrierefrei, wenn es ohne Stufe oder sonstigen Erhöhungen zugänglich ist. Eine nachträgliche Barrierefreiheit kann unter Umständen durch z.B. den Einbau einer Rampe gewährleistet werden."
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'accessibilityType')}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <SwitchField
                                label="Ebenerdige Verkaufsfläche vorhanden"
                                description="Ist Deine Verkaufsfläche ebenerdig? Gibt es auf der Verkaufsfläche keinerlei Höhenunterschiede (Rampen, Treppen, Stufe,…), gilt sie als ebenerdig. Ist die Verkaufsfläche über mehrere Etagen aufgeteilt und über einen Aufzug verbunden, gilt sie dennoch als ebenerdig."
                                selected={detail.groundLevelSalesArea}
                                name="groundLevelSalesArea"
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <SwitchField
                                label="Parkplätze am Objekt"
                                description="Sind direkt an Deiner Fläche Parkplätze vorhanden?"
                                selected={detail.parkingLotAvailable}
                                name="parkingLotAvailable"
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                    {detail.parkingLotAvailable === true &&
                        <div className="row">
                            <div className="col-12 col-lg-6 mb-3">
                                <InputField
                                    name="numberOfParkingLots"
                                    label="Anzahl Parkplätze"
                                    description="Gib hier die genaue Anzahl der verfügbaren Parkplätze an."
                                    type="number"
                                    min={0}
                                    required={false}
                                    value={detail.numberOfParkingLots ?? undefined}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                                <InputField
                                    name="parkingLotsExplanation"
                                    label="Erläuterung zu Parkplätzen"
                                    description="Musst Du noch etwas zu Deinen Parkplätzen erläutern? Handelt es sich z. B. um Tiefgaragenstellplätze oder sind diese im Hinterhof?"
                                    type="text"
                                    required={false}
                                    value={detail.parkingLotsExplanation ?? undefined}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    }
                    <div className="row mb-3">
                        <div className="col-12">
                            <SelectField
                                name="lastUsage"
                                label="Letzte Nutzung"
                                required={false}
                                options={lastUsageSelectOptions}
                                value={fetchLastUsageSelectedOption()}
                                placeholder="Bitte wählen"
                                onChange={handleLastUsageChange}
                                description="Hier kannst Du optional angeben, was die letzte Nutzung Deiner Fläche war."
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <TextAreaField
                        style={{gridRowEnd: 'span 5', alignSelf: 'baseline'}}
                        name="objectDescription"
                        label="Objektbeschreibung"
                        rows={9}
                        required={true}
                        value={detail.objectDescription ?? undefined}
                        description="Beschreibe Deine Fläche. Was macht Deine Fläche aus? Was gibt es für Besonderheiten? Wie wurde sie zuletzt genutzt?"
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(props.formData, 'objectDescription')}
                    />
                </div>
            </div>
        </>
    );
};

export default DetailForm;
