import textBadgeStyle from './TextBadge.module.scss';
import React from 'react';

interface TextBadgeProps {
    text: string;
    colorScheme?: 'primary' | 'secondary' | 'light-grey';
    textSize?: 'small' | 'large';
}

const TextBadge = (props: TextBadgeProps): React.JSX.Element => {
    const buildColorScheme = (): string => {
        if (props.colorScheme === undefined) {
            return '';
        }

        switch (props.colorScheme) {
            case 'primary':
                return textBadgeStyle.primary;
            case 'secondary':
                return textBadgeStyle.secondary;
            case 'light-grey':
                return textBadgeStyle.lightGrey;
        }
    };

    const buildTextSize = (): string => {
        if (props.textSize === 'large') {
            return textBadgeStyle.fsLarge;
        }

        return '';
    };

    return (
        <div className={[textBadgeStyle.badge, buildColorScheme(), buildTextSize(), 'text-center px-2 py-1'].join(' ')}>
            {props.text}
        </div>
    );
};

export default TextBadge;
