import SortingOption from '../../Entity/SortingOption/SortingOption';
import BootstrapIcon from '../../Component/Icon/BootstrapIcon';
import React from 'react';

interface SortingProps {
    readonly sortingOptions: SortingOption[];
    readonly selectedSortingOption: SortingOption | null;
    readonly onChange: (sortingOption: SortingOption | null) => void;
}

const Sorting = (props: SortingProps): React.JSX.Element => {
    const onChange = (sortingOption: SortingOption | null): void => {
        props.onChange(sortingOption);
    };

    return (
        <div className="dropdown">
            <button
                type="button"
                className="btn p-3 position-relative"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <BootstrapIcon iconName="sort-down-alt" className="me-2" />
                Sortierung
            </button>
            <ul className="dropdown-menu dropdown-menu-end text-secondary">
                <li className="ms-3 fw-bold">Sortieren nach:</li>
                {props.sortingOptions.map((sortingOption: SortingOption): React.JSX.Element => (
                    <li key={sortingOption.sortingBy + sortingOption.sortingDirection}>
                        <button
                            className={['dropdown-item', sortingOption === props.selectedSortingOption ? 'active' : ''].join(' ')}
                            onClick={(): void => onChange(sortingOption)}
                        >
                            {sortingOption.bootstrapIconName !== null &&
                                <BootstrapIcon iconName={sortingOption.bootstrapIconName} className="me-2" />
                            }
                            {sortingOption.label}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sorting;
