import React, {useEffect} from 'react';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Stripe, StripeElements, StripePaymentElementOptions} from '@stripe/stripe-js';

interface StripePaymentFormProps {
    readonly stripePaymentElementOptions: StripePaymentElementOptions;
    readonly setElements: (stripeElements: StripeElements) => void;
    readonly setStripe: (stripe: Stripe) => void;
}

const StripePaymentElement = (props: StripePaymentFormProps): React.JSX.Element => {
    const elements = useElements();

    const stripe = useStripe();

    useEffect((): void => {
        if (stripe !== null) {
            props.setStripe(stripe);
        }

        if (elements !== null) {
            props.setElements(elements);
        }

        /*
        const clientSecret: string | null = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

        if (clientSecret === null) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}): void => {
            if (paymentIntent === undefined) {
                setMessage('Something went wrong.');

                return;
            }

            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
*/
    }, [stripe]);

    return <PaymentElement options={props.stripePaymentElementOptions} />;
};

export default StripePaymentElement;
