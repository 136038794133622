import InfoBox from '../../shared/Component/InfoBox/InfoBox';
import marketplaceAdWide from './marketplace-banner-wide.png';
import marketplaceAdSmall from './marketplace-banner-small.png';
import React from 'react';
import {Link} from 'react-router-dom';

const bannerEndDate: Date = new Date('2025-01-31');

const MarketplaceBanner = (): React.JSX.Element => {
    if (bannerEndDate <= new Date()) {
        return (
            <></>
        );
    }

    return (
        <InfoBox cardType="shadow" className="p-3 mb-5 text-center bg-white text-secondary">
            <Link to="/marktplatz">
                <div className="d-none d-lg-block">
                    <img src={marketplaceAdWide} alt="Entdecke jetzt unseren neuen Marktplatz!" className="img-fluid" />
                </div>
                <div className="d-block d-lg-none">
                    <img src={marketplaceAdSmall} alt="Entdecke jetzt unseren neuen Marktplatz!" className="img-fluid" />
                </div>
            </Link>
        </InfoBox>
    );
};

export default MarketplaceBanner;
