import MatchingResultStatusEntity, {getMatchingResultStatusLabel} from '../../Entity/Matching/MatchingResultStatus';
import TextBadge from '../../Component/TextBadge/TextBadge';
import Tooltip from '../../Component/Tooltip/Tooltip';
import React from 'react';

interface MatchingResultStatusProps {
    readonly matchingResultStatuses: MatchingResultStatusEntity[];
    readonly textSize?: 'small' | 'large';
    readonly orientation?: 'vertical' | 'horizontal';
}

const MatchingResultStatus = (props: MatchingResultStatusProps): React.JSX.Element => {
    const buildColorScheme = (matchingResultStatus: MatchingResultStatusEntity): 'primary' | 'secondary' | 'light-grey' => {
        switch (matchingResultStatus) {
            case MatchingResultStatusEntity.NewlyCreated:
                return 'secondary';
            case MatchingResultStatusEntity.RecentlyUpdated:
                return 'light-grey';
            case MatchingResultStatusEntity.NewlyFound:
                return 'primary';
        }
    };

    const buildTooltip = (matchingResultStatus: MatchingResultStatusEntity): string => {
        switch (matchingResultStatus) {
            case MatchingResultStatusEntity.NewlyCreated:
                return 'Dieses Gesuch wurde in den letzten 14 Tagen neu in die Plattform eingestellt.';
            case MatchingResultStatusEntity.RecentlyUpdated:
                return 'Die Daten dieses Gesuchs wurden in den letzten 14 Tagen aktualisiert.';
            case MatchingResultStatusEntity.NewlyFound:
                return 'Dieses Gesuch ist neu in deiner Ergebnisliste für den aktuell gesetzten Branchenfilter.';
        }
    };

    return (
        <div className={props.orientation === 'horizontal' ? 'd-flex' : ''}>
            {props.matchingResultStatuses.map((matchingResultStatus: MatchingResultStatusEntity): React.JSX.Element => (
                <div className={['mb-2', props.orientation === 'horizontal' ? 'me-2' : ''].join(' ')}>
                    <Tooltip title={buildTooltip(matchingResultStatus)}>
                        <TextBadge
                            key={matchingResultStatus}
                            text={getMatchingResultStatusLabel(matchingResultStatus)}
                            colorScheme={buildColorScheme(matchingResultStatus)}
                            textSize={props.textSize}
                        />
                    </Tooltip>
                </div>
            ))}
        </div>
    );
};

export default MatchingResultStatus;
