import Property from '../Entity/Property/Property';
import ImageEntity from '../Entity/Image/Image';
import PropertyDetailInformationOverlay from './PropertyDetailInformationOverlay';
import PropertyService from './PropertyService';
import MarketingStatusBadge from './MarketingStatusBadge';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import Card from '../Component/Card/Card';
import CardContent from '../Component/Card/CardContent';
import CardFooter from '../Component/Card/CardFooter';
import ImageSliderCardMedia from '../Component/Card/ImageSliderCardMedia';
import IconBadgeWrapper from '../Component/IconBadge/IconBadgeWrapper';
import StatusIndicator from '../Component/StatusIndicator/StatusIndicator';
import Tooltip from '../Component/Tooltip/Tooltip';
import noImage from '../../../img/no-brand-logo.jpg';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

interface PropertyCardProps {
    readonly property: Property;
}

const PropertyCard = (props: PropertyCardProps): React.JSX.Element => {
    const [showDetailInformationOverlay, setShowDetailInformationOverlay] = useState<boolean>(false);

    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const buildThumbnailImageGetPath = (image: ImageEntity): string => {
        return PropertyService.buildThumbnailImageApiPath(props.property, image);
    };

    return (
        <Card cardType="shadow" className="position-relative h-100">
            <StatusIndicator active={props.property.active} right={-10} top={-10} />
            {cardMediaContainerHeight !== undefined &&
                <IconBadgeWrapper positionTop={cardMediaContainerHeight - 50} positionRight={-10}>
                    <MarketingStatusBadge marketingStatus={props.property.marketingStatus} />
                </IconBadgeWrapper>
            }
            <ImageSliderCardMedia
                images={props.property.images}
                noImage={noImage}
                buildThumbnailImageGetPath={buildThumbnailImageGetPath}
                cardMediaContainerHeight={cardMediaContainerHeight}
                setCardMediaContainerHeight={setCardMediaContainerHeight}
            />
            <CardContent className="px-2 px-md-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="fw-bolder">{props.property.title}</div>
                    <div className="text-nowrap ms-2">
                        <button className="btn btn-outline-secondary btn-sm me-2" onClick={(): void => setShowDetailInformationOverlay(true)}>
                            <i className="bi bi-eye-fill"></i>
                        </button>
                        <Link to={'/flaechen/' + props.property.id + ''} className="btn btn-sm btn-secondary">
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    </div>
                </div>
                <div className="mb-4">
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Fläche-Nr.">
                                <BootstrapIcon iconName="shop-window" />
                            </Tooltip>
                        </div>
                        <div className="fw-bold">
                            #{props.property.id}
                            {props.property.internalDesignation !== null &&
                                <span className="ms-1">({props.property.internalDesignation})</span>
                            }
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Lage des Objekts">
                                <BootstrapIcon iconName="geo-alt-fill" />
                            </Tooltip>
                        </div>
                        <div>
                            {props.property.locationDetail.streetName !== null &&
                                <div>{props.property.locationDetail.streetName} {props.property.locationDetail.houseNumber}</div>
                            }
                            <div>{props.property.locationDetail.postalCode} {props.property.locationDetail.city!.placeName}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Flächenangaben">
                                <BootstrapIcon iconName="bounding-box-circles" />
                            </Tooltip>
                        </div>
                        <div>
                            <div className="mb-1">{new Intl.NumberFormat('de-DE').format(props.property.detail.areaSize)} m² (Verkaufs-)Fläche</div>
                            {props.property.detail.secondaryAreaSize !== null &&
                                <div>{new Intl.NumberFormat('de-DE').format(props.property.detail.secondaryAreaSize)} m² Nebenfläche</div>
                            }
                        </div>
                    </div>
                </div>
                <div style={{'height': '50px'}}></div>
            </CardContent>
            <CardFooter className="position-absolute bottom-0 w-100">
                <div className="d-flex justify-content-between">
                    {props.property.active === true &&
                        <Link to={'/flaechen/' + props.property.id + '/matching'} className="btn btn-primary mx-auto text-nowrap">
                            <i className="bi bi-arrow-repeat me-2"></i> Let's match
                        </Link>
                    }
                    {props.property.active === false &&
                        <Tooltip title="Das Ausführen des Matchings ist nur für aktive Flächen möglich." className="mx-auto">
                            <button disabled={true} className="btn btn-primary mx-auto text-nowrap">
                                <i className="bi bi-arrow-repeat me-2"></i> Let's match
                            </button>
                        </Tooltip>
                    }
                </div>
            </CardFooter>
            <PropertyDetailInformationOverlay
                show={showDetailInformationOverlay}
                setShow={setShowDetailInformationOverlay}
                property={props.property}
            />
        </Card>
    );
};

export default PropertyCard;
