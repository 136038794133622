import SettlementConcept from '../Entity/SettlementConcept/SettlementConcept';
import IndustryClassification from '../Entity/IndustryClassification/IndustryClassification';
import FormData from '../Entity/Form/FormData';
import PlaceCriteria from '../Entity/SettlementConcept/PlaceCriteria';
import PropertyCriteria from '../Entity/SettlementConcept/PropertyCriteria';
import SelectOption from '../Entity/Form/SelectOption';
import Place from '../Entity/Location/Place';
import FormError from '../Entity/Form/FormError';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import SelectOrCreateContact from '../Contact/SelectOrCreateContact';
import SelectOrCreateBrand from '../Brand/SelectOrCreateBrand';
import PropertyCriteriaForm from './PropertyCriteriaForm';
import PlaceCriteriaForm from './PlaceCriteriaForm';
import IndustryClassificationSelectForm from '../IndustryClassification/IndustryClassificationSelectForm';
import Card from '../Component/Card/Card';
import CollapseCard from '../Component/CollapseCard/CollapseCard';
import SwitchField from '../Component/Form/Field/SwitchField';
import TextAreaField from '../Component/Form/Field/TextAreaField';
import InputField from '../Component/Form/Field/InputField';
import MultiSelectField from '../Component/Form/Field/MultiSelectField';
import Divider from '../Component/Divider/Divider';
import React, {useEffect, useState} from 'react';

interface SettlementConceptFormProps {
    readonly formData: FormData<SettlementConcept>;
    readonly setFormData: (formData: FormData<SettlementConcept>) => void;
    readonly propertyCriteriaFormData: FormData<PropertyCriteria>;
    readonly setPropertyCriteriaFormData: (formData: FormData<PropertyCriteria>) => void;
    readonly placeCriteriaFormDatas: FormData<PlaceCriteria>[];
    readonly setPlaceCriteriaFormDatas: (formDatas: FormData<PlaceCriteria>[]) => void;
    readonly industryClassifications: IndustryClassification[];
    readonly countryPlaces: Place[];
    readonly hasIndustryClassificationError?: boolean;
    readonly hasCountryPlacesError: boolean;
    readonly setHasLocationCategoryErrors: (hasLocationCategoryErrors: boolean) => void;
    readonly setHasCountryPlacesBeenChanged? : (hasCountryPlacesBeenTouched: boolean) => void;
    readonly formValidationHandler?: FormValidationHandler<SettlementConcept>;
    readonly propertyCriteriaFormValidationHandler?: FormValidationHandler<PropertyCriteria>;
}

const placeCriteriaFieldValidationDefinitions: FieldValidationDefinition<PlaceCriteria>[] = [
    new RequiredValidationDefinition<PlaceCriteria>('expansionStates', 'Es muss mindestens ein Expansionsgebiet gewählt werden.'),
    new RequiredValidationDefinition<PlaceCriteria>('cityPopulationMinimum', 'Es muss eine Mindesteinwohnerzahl angegeben werden.'),
];

const SettlementConceptForm = (props: SettlementConceptFormProps): React.JSX.Element => {
    const settlementConcept: SettlementConcept = props.formData.data;

    const [countryPlaceSelectOptions, setCountryPlaceSelectOptions] = useState<SelectOption<Place>[]>();

    const [countryPlaceSelectedOptions, setCountryPlaceSelectedOptions] = useState<SelectOption<Place>[]>([]);

    useEffect((): void => {
        const countryPlaceSelectOptions: SelectOption<Place>[] = props.countryPlaces.map((place: Place): SelectOption<Place> => {
            return {
                label: place.placeName,
                value: place
            };
        });

        setCountryPlaceSelectOptions(countryPlaceSelectOptions);
    }, [props.countryPlaces]);


    useEffect((): void => {
        props.placeCriteriaFormDatas.forEach((placeCriteriaFormData: FormData<PlaceCriteria>): void  => {
            if (placeCriteriaFormData.formValidationHandler === undefined) {
                placeCriteriaFormData.formValidationHandler = new FormValidationHandler<PlaceCriteria>(placeCriteriaFieldValidationDefinitions);
            }
        });

    }, [props.placeCriteriaFormDatas]);


    useEffect((): void => {
        if (countryPlaceSelectOptions === undefined) {
            return;
        }

        if (props.formData.data.placeCriterias.length === 0) {
            return;
        }

        const countryPlaceSelectedOptions: SelectOption<Place>[] = [];

        props.formData.data.placeCriterias.forEach((placeCriteria: PlaceCriteria): void => {
            const countryPlaceSelectOption: SelectOption<Place> | undefined = countryPlaceSelectOptions.find((countryPlaceSelectOption: SelectOption<Place>): boolean => {
                return placeCriteria.countryPlace.id === countryPlaceSelectOption.value.id;
            });

            if (countryPlaceSelectOption !== undefined) {
                countryPlaceSelectedOptions.push(countryPlaceSelectOption);
            }
        });

        setCountryPlaceSelectedOptions(countryPlaceSelectedOptions);
    }, [countryPlaceSelectOptions]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
        switch (event.target.name) {
            case 'desiredMinimumLeaseTerm':
                if (event.target.value === '') {
                    settlementConcept.desiredMinimumLeaseTerm = null;
                } else {
                    settlementConcept.desiredMinimumLeaseTerm = Number(event.target.value);
                }

                break;
            default:
                (settlementConcept as any)[event.target.name] = event.target.value;

                break;
        }

        validateField(event.target.name);
        updateFormData();
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        (settlementConcept as any)[event.target.name] = event.target.checked;

        updateFormData();
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: settlementConcept});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    const setIndustryClassificationsFormData = (industryClassificationFormData: FormData<IndustryClassification[]>): void => {
        props.formData.data.industryClassifications = industryClassificationFormData.data;

        updateFormData();
    };

    const addIndustryClassification = (): void => {
        props.formData.data.industryClassifications.push(new IndustryClassification());

        updateFormData();
    };

    const removeIndustryClassification = (index: number): void => {
        props.formData.data.industryClassifications = props.formData.data.industryClassifications.filter((industryClassification: IndustryClassification, _index: number): boolean => _index !== index);

        updateFormData();
    };

    const setPlaceCriteriaFormData = (placeCriteriaFormDataToBeSet: FormData<PlaceCriteria>): void => {
        const placeCriteriaFormDatas: FormData<PlaceCriteria>[] = props.placeCriteriaFormDatas.slice();

        const index: number = placeCriteriaFormDatas.findIndex((placeCriteriaFormData: FormData<PlaceCriteria>): boolean => {
            return placeCriteriaFormData.data.countryPlace.id === placeCriteriaFormDataToBeSet.data.countryPlace.id;
        });

        if (index === -1) {
            return;
        }

        placeCriteriaFormDatas[index] = placeCriteriaFormDataToBeSet;

        props.setPlaceCriteriaFormDatas(placeCriteriaFormDatas);
    };

    const handleCountryPlacesChange = (selectedCountries: readonly SelectOption<Place>[]): void => {
        const selectedCountryPlaces: Place[] = selectedCountries.map((selectedCountry: SelectOption<Place>): Place => {
            return selectedCountry.value;
        });

        updatePlaceCriteriaFormDatas(selectedCountryPlaces);

        const countryPlaceSelectedOptions: SelectOption<Place>[] = selectedCountryPlaces.map((place: Place): SelectOption<Place> => {
            return {
                label: place.placeName,
                value: place
            };
        });

        setCountryPlaceSelectedOptions(countryPlaceSelectedOptions);

        if (props.setHasCountryPlacesBeenChanged !== undefined) {
            props.setHasCountryPlacesBeenChanged(true);
        }
    };

    const updatePlaceCriteriaFormDatas = (countryPlaces: Place[]): void => {
        const placeCriteriaFormDatas: FormData<PlaceCriteria>[] = [];

        countryPlaces.forEach((countryPlace: Place): void => {
            const placeCriteriaFormData: FormData<PlaceCriteria> | undefined = props.placeCriteriaFormDatas.find((placeCriteriaFormData: FormData<PlaceCriteria>): boolean => {
                return countryPlace.id === placeCriteriaFormData.data.countryPlace.id;
            });

            if (placeCriteriaFormData !== undefined) {
                placeCriteriaFormDatas.push(placeCriteriaFormData);
            } else {
                const placeCriteria: PlaceCriteria = new PlaceCriteria();

                placeCriteria.targetedCitySearch = false;
                placeCriteria.countryPlace = countryPlace;

                placeCriteriaFormDatas.push({data: placeCriteria, formValidationHandler: new FormValidationHandler<PlaceCriteria>(placeCriteriaFieldValidationDefinitions)});
            }
        });

        props.setPlaceCriteriaFormDatas(placeCriteriaFormDatas);
    };

    return (
        <>
            {settlementConcept.id !== undefined &&
                <Card cardType="shadow" className="mb-3 bg-light">
                    <div className="p-3 p-lg-4 p-xl-5">
                        <h2 className="fs-5 fw-bold mb-3">Status</h2>
                        <div className="mb-3">
                            <SwitchField
                                label={settlementConcept.active === true ? 'Suchprofil ist nach dem Speichern aktiv' : 'Suchprofil ist nach dem Speichern inaktiv'}
                                description="Hier kannst Du Dein Suchprofil jederzeit aktiv bzw. inaktiv stellen. Nur aktive Suchprofile werden beim Matching berücksichtigt."
                                selected={settlementConcept.active}
                                name="active"
                                onChange={handleSwitchChange}
                            />
                        </div>
                    </div>
                </Card>
            }
            <CollapseCard cardType="shadow" title="Ansprechpartner auswählen" className="mb-3">
                <p className="mb-4">
                    Ansprechpartner werden bei Deinem Suchprofil oder Deinem Flächenangebot für Interessenten als Kontaktpersonen genannt.
                    Ihre Kontaktdaten sind nach dem Matching einsehbar. Über den Plus-Button fügst Du neue Ansprechpartner hinzu.
                </p>
                <SelectOrCreateContact
                    formData={props.formData}
                    setFormData={props.setFormData}
                    validateField={validateField}
                />
            </CollapseCard>

            <CollapseCard cardType="shadow" title="Informationen zum Unternehmen/Geschäft bzw. zur Marke" className="mb-3">
                <div className="mb-4">
                    Wähle hier die Marke oder den Namen des Geschäfts aus. Über den Plus-Button fügst Du neue Marken oder Namen hinzu.
                </div>
                <SelectOrCreateBrand
                    formData={props.formData}
                    setFormData={props.setFormData}
                    validateField={validateField}
                />
            </CollapseCard>

            <CollapseCard cardType="shadow" title="Allgemeine Suchinformationen" className="mb-3">
                <div className="row">
                    <div className="col-md-12 col-xl-8 mb-3">
                        <div className="mb-3">
                            <InputField
                                label="Name des Suchprofils"
                                name="title"
                                required={true}
                                value={settlementConcept.title}
                                description="Trage hier eine Bezeichnung / einen Titel für Dein Flächengesuch ein, um es von Deinen anderen Gesuchen zu unterscheiden. Dieser Name erscheint auch im Matching."
                                formErrors={FormValidationHandler.getFieldErrors(props.formData, 'title')}
                                onChange={handleChange}
                            />
                        </div>
                        {settlementConcept.industryClassifications.map((industryClassification: IndustryClassification, index: number): React.JSX.Element => (
                            <div key={industryClassification.uniqueKey} className={`d-flex flex-column flex-lg-row gap-3 ${index === 0 ? 'mb-5 mb-lg-4' : 'mb-4'}`}>
                                <IndustryClassificationSelectForm
                                    industryClassifications={props.industryClassifications}
                                    formData={{data: settlementConcept.industryClassifications}}
                                    setFormData={setIndustryClassificationsFormData}
                                    index={index}
                                    hasIndustryClassificationError={props.hasIndustryClassificationError}
                                />
                                {index > 0 &&
                                    <div className="text-end align-self-end">
                                        <button type="button" className="btn" onClick={(): void => removeIndustryClassification(index)}>
                                            <i className="bi bi-trash text-danger"></i>
                                        </button>
                                    </div>
                                }
                            </div>
                        ))}
                        {props.hasIndustryClassificationError === true &&
                            <div className="invalid-feedback d-block mb-3">Es muss mindestens eine Kategorie angegeben werden.</div>
                        }
                        <div className="text-start">
                            <button type="button" className="btn btn-secondary" onClick={(): void => addIndustryClassification()}>
                                <i className="bi bi-plus-lg fs-6 px-2"></i>Weitere Kategorie
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 col-xl-4 mb-3">
                        <TextAreaField
                            name="description"
                            label="Beschreibung"
                            rows={7}
                            required={true}
                            value={settlementConcept.description}
                            description="Was macht Dein Konzept aus? Wie unterscheidet es sich von anderen?"
                            onChange={handleChange}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'description')}
                        />
                    </div>
                </div>
            </CollapseCard>
            <CollapseCard cardType="shadow" title="Anforderungen an den Standort" className="mb-3">
                {countryPlaceSelectOptions !== undefined &&
                    <div className="row mb-5">
                        <div className="col-lg-4">
                            <MultiSelectField
                                label="Länder"
                                name="countryPlaces"
                                options={countryPlaceSelectOptions}
                                required={true}
                                closeMenuOnSelect={false}
                                value={countryPlaceSelectedOptions}
                                placeholder="Bitte wählen"
                                onChange={handleCountryPlacesChange}
                                formErrors={props.hasCountryPlacesError === true ? [new FormError('countryPlaces', 'Es muss mindestens ein Land ausgewählt sein.')] : []}
                            />
                        </div>
                    </div>
                }
                {props.placeCriteriaFormDatas.map((placeCriteriaFormData: FormData<PlaceCriteria>, index: number): React.JSX.Element => (
                    <div key={'placeCriteriaForm-' + placeCriteriaFormData.data.countryPlace.id}>
                        <h6 className="mb-3"><strong>{placeCriteriaFormData.data.countryPlace.placeName}</strong></h6>
                        <PlaceCriteriaForm formData={placeCriteriaFormData} setFormData={setPlaceCriteriaFormData} />

                        {props.placeCriteriaFormDatas.length - 1 > index &&
                            <Divider color="lightgrey" />
                        }
                    </div>
                ))}
            </CollapseCard>

            <CollapseCard cardType="shadow" title="Expansionsanforderungen/Flächenanforderungen" className="mb-3">
                <PropertyCriteriaForm
                    formData={props.propertyCriteriaFormData}
                    setFormData={props.setPropertyCriteriaFormData}
                    setHasLocationCategoryErrors={props.setHasLocationCategoryErrors}
                    formValidationHandler={props.propertyCriteriaFormValidationHandler}
                />
            </CollapseCard>

            <CollapseCard cardType="shadow" title="Weitere Angaben/Anforderungen" className="mb-3">
                <div className="row mb-3">
                    <div className="col-md-6 col-xl-3 mb-3">
                        <InputField
                            label="Gewünschte Mindestvertragslaufzeit"
                            name="desiredMinimumLeaseTerm"
                            required={false}
                            type="number"
                            min={0}
                            suffix="Monate"
                            value={settlementConcept.desiredMinimumLeaseTerm ?? undefined}
                            description="Für wie viele Monate möchtest Du Flächen mindestens anmieten?"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-lg-6 col-xl-4 mb-3">
                        <SwitchField
                            label="Interesse an Popup-Store-Flächen"
                            description="Hast Du Interesse an Pop-Up Flächen? Damit sind Flächen gemeint, die nur für eine bestimmte Zeit (meistens 2-6 Monate) vergeben werden und dann wieder an neue Nachmieter vermittelt werden. Dort kannst Du für einen kurzen Zeitraum austesten, ob z.B. die Region für Dich als feste Ansiedlung rentabel ist oder austesten, wie gut ein neues Produkt oder eine neue Dienstleistung bei potentiellen Kunden ankommt."
                            selected={settlementConcept.interestPopupSpace}
                            name="interestPopupSpace"
                            onChange={handleSwitchChange}
                        />
                        {settlementConcept.interestPopupSpace === true &&
                            <div className="mt-3">
                                <SwitchField
                                    label="Ich suche ausschließlich Popup-Store-Flächen"
                                    description="Suchst Du ausschließlich Popup-Store-Flächen für Dein Konzept? Wenn ja, dann gib dies hier an, damit die Flächenanbieter einen entsprechenden Hinweis erhalten."
                                    selected={settlementConcept.onlyPopupSpace}
                                    name="onlyPopupSpace"
                                    onChange={handleSwitchChange}
                                />
                            </div>
                        }
                    </div>
                    <div className="col-lg-6 col-xl-4 mb-3">
                        <SwitchField
                            label="Interesse an Mixed-Use-Flächen"
                            description="Hast Du Interesse an Mixed-Use Flächen? Damit sind Flächen gemeint, die entweder räumlich oder zeitlich getrennt gemeinsam von mehreren Konzeptbetreibern genutzt werden."
                            selected={settlementConcept.interestMixedUseSpace}
                            name="interestMixedUseSpace"
                            onChange={handleSwitchChange}
                        />
                        {settlementConcept.interestMixedUseSpace === true &&
                            <div className="mt-3">
                                <SwitchField
                                    label="Ich suche ausschließlich Mixed-Use-Flächen"
                                    description="Suchst Du auscchließlich Mixed-Use-Flächen für Dein Konzept? Wenn ja, dann gib dies hier an, damit die Flächenanbieter einen entsprechenden Hinweis erhalten."
                                    selected={settlementConcept.onlyMixedUseSpace}
                                    name="onlyMixedUseSpace"
                                    onChange={handleSwitchChange}
                                />
                            </div>
                        }
                    </div>
                </div>
            </CollapseCard>
        </>
    );
};

export default SettlementConceptForm;
