import Breadcrumb from '../shared/Entity/Breadcrumb/Breadcrumb';
import BreadcrumbState from '../shared/Entity/Breadcrumb/BreadcrumbState';
import UserState from '../shared/Entity/User/UserState';
import {useAppSelector} from '../../app/hooks';
import clover from '../../img/logo-bildmarke.svg';
import React from 'react';
import {Link} from 'react-router-dom';

const SubHeader = (): React.JSX.Element => {
    const {user}: UserState = useAppSelector<UserState>(state => state.user);

    const {breadcrumbs}: BreadcrumbState = useAppSelector<BreadcrumbState>(state => state.breadcrumb);

    return (
        <div className="d-flex justify-content-between align-items-center py-4">
            <nav aria-label="breadcrumb" className="d-none d-md-flex text-secondary">
                <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item" key="0">
                        <Link to="/" className="text-decoration-none text-dark">
                            <i className="bi bi-columns-gap me-1 pt-1 fs-5 bi-align-bottom"></i> Dashboard
                        </Link>
                    </li>

                    {breadcrumbs.map((breadcrumb: Breadcrumb, index: number): React.JSX.Element => (
                        <li key={index} className="breadcrumb-item mt-1">
                            {breadcrumb.link !== undefined
                                ?
                                <Link to={breadcrumb.link} className="text-decoration-none text-dark">
                                    {breadcrumb.name}
                                </Link>
                                : <span>{breadcrumb.name}</span>
                            }
                        </li>
                    ))}
                </ol>
            </nav>
            {user !== undefined &&
                <Link to="/match-points" className="me-3 d-flex align-items-center text-secondary text-decoration-none">
                    <img src={clover} className="match-point-icon me-2" alt="MatchPoints Icon" />
                    <span className="text-nowrap">
                        <span className="me-1">{new Intl.NumberFormat('de-DE').format(user.numberOfMatchPoints)}</span>
                        MatchPoints
                    </span>
                </Link>
            }
        </div>
    );
};

export default SubHeader;
