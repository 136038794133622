import MatchingResult from './MatchingResult';
import Brand from '../Brand/Brand';
import MatchingResultGroupResponse from '../../../../api/Llasm/Matching/MatchingResultGroupResponse';
import MatchingResultResponse from '../../../../api/Llasm/Matching/MatchingResultResponse';

class MatchingResultGroup {
    private _brand: Brand;

    private _locked: boolean;

    private _amountOfNewlyCreated: number;

    private _amountOfRecentlyUpdated: number;

    private _amountOfNewlyFound: number;

    private _matchingResults: MatchingResult[] = [];

    public static createFromMatchingResultGroupResponse(matchingResultGroupResponse: MatchingResultGroupResponse): MatchingResultGroup {
        const matchingResultGroup: MatchingResultGroup = new this();

        matchingResultGroup._brand = Brand.createFromBrandResponse(matchingResultGroupResponse.brand);
        matchingResultGroup._locked = matchingResultGroupResponse.locked;
        matchingResultGroup._amountOfNewlyCreated = matchingResultGroupResponse.amountOfNewlyCreated;
        matchingResultGroup._amountOfRecentlyUpdated = matchingResultGroupResponse.amountOfRecentlyUpdated;
        matchingResultGroup._amountOfNewlyFound = matchingResultGroupResponse.amountOfNewlyFound;

        matchingResultGroup._matchingResults = matchingResultGroupResponse.matchingResults.map(
            (matchingResultResponse: MatchingResultResponse): MatchingResult => {
                return MatchingResult.createFromMatchingResultResponse(matchingResultResponse);
            }
        );

        return matchingResultGroup;
    }

    get brand(): Brand {
        return this._brand;
    }

    set brand(value: Brand) {
        this._brand = value;
    }

    get locked(): boolean {
        return this._locked;
    }

    set locked(value: boolean) {
        this._locked = value;
    }

    get amountOfNewlyCreated(): number {
        return this._amountOfNewlyCreated;
    }

    set amountOfNewlyCreated(value: number) {
        this._amountOfNewlyCreated = value;
    }

    get amountOfRecentlyUpdated(): number {
        return this._amountOfRecentlyUpdated;
    }

    set amountOfRecentlyUpdated(value: number) {
        this._amountOfRecentlyUpdated = value;
    }

    get amountOfNewlyFound(): number {
        return this._amountOfNewlyFound;
    }

    set amountOfNewlyFound(value: number) {
        this._amountOfNewlyFound = value;
    }

    get matchingResults(): MatchingResult[] {
        return this._matchingResults;
    }

    set matchingResults(value: MatchingResult[]) {
        this._matchingResults = value;
    }
}

export default MatchingResultGroup;
