import PropertyService from './PropertyService';
import Property from '../Entity/Property/Property';
import Contact from '../Entity/Contact/Contact';
import ImageEntity from '../Entity/Image/Image';
import {getLocationTypeLabel} from '../Entity/LocationType';
import {getLocationCategoryLabel} from '../Entity/LocationCategory';
import PropertyDetailInformationOverlay from './PropertyDetailInformationOverlay';
import MarketingStatusBadge from './MarketingStatusBadge';
import Image from '../Image/Image';
import BootstrapIcon from '../Component/Icon/BootstrapIcon';
import Card from '../Component/Card/Card';
import StatusIndicator from '../Component/StatusIndicator/StatusIndicator';
import CardMedia from '../Component/Card/CardMedia';
import Slider from '../Component/Slider/Slider';
import CardContent from '../Component/Card/CardContent';
import IconBadgeWrapper from '../Component/IconBadge/IconBadgeWrapper';
import Tooltip from '../Component/Tooltip/Tooltip';
import propertySliderStyle from './PropertySlider.module.scss';
import noImage from '../../../img/no-brand-logo.jpg';
import React, {useEffect, useState} from 'react';
import {Link, NavigateFunction, useNavigate} from 'react-router-dom';

interface PropertySliderProps {
    readonly properties: Property[];
    readonly perView?: number;
}

const minMediumDeviceWidth: number = 768;
let windowWidth: number = window.innerWidth;

const PropertySlider = (props: PropertySliderProps): React.JSX.Element => {
    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const [showPropertyDetailInformationOverlay, setShowPropertyDetailInformationOverlay] = useState<boolean>(false);

    const [propertyDetailInformationItem, setPropertyDetailInformationItem] = useState<Property>();

    const [showArrows, setShowArrows] = useState<boolean>(true);

    useEffect((): void => {
        const showArrows: boolean = props.properties.length > 0 && window.innerWidth > minMediumDeviceWidth;

        setShowArrows(showArrows);

        window.addEventListener('resize', handleWindowResize);
    }, []);

    const handleWindowResize = (): void => {
        if (
            (window.innerWidth < minMediumDeviceWidth && windowWidth >= minMediumDeviceWidth)
            || (window.innerWidth >= minMediumDeviceWidth && windowWidth < minMediumDeviceWidth)
        ) {
            const showArrows: boolean = props.properties.length > 0 && window.innerWidth > minMediumDeviceWidth;

            setShowArrows(showArrows);
        }

        windowWidth = window.innerWidth;
    };

    if (props.properties.length === 0) {
        return (
            <Card cardType="shadow" className="position-relative carousel-card">
                <CardMedia setContainerHeight={setCardMediaContainerHeight}>
                    <div className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                        <img src={noImage} alt="Kein Bild" className="w-100" />
                    </div>
                </CardMedia>
                <CardContent>
                    <div className="text-center pt-4 mb-3 fw-bold">
                        Du hast derzeit kein Ladenlokal angelegt.
                    </div>
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            <Slider
                config={{
                    type: 'slider',
                    showArrows: showArrows,
                    arrowsPosition: 'outside',
                    rewind: true,
                    perView: props.perView !== undefined ? props.perView : 1,
                    breakpoints: {
                        992: {
                            perView: 1
                        }
                    },
                    bound: true
                }}
            >
                {props.properties.map((property: Property): React.JSX.Element => (
                    <PropertyCard
                        key={'property-' + property.id}
                        property={property}
                        setShowPropertyDetailInformationOverlay={setShowPropertyDetailInformationOverlay}
                        setPropertyDetailInformationItem={setPropertyDetailInformationItem}
                    />
                ))}
            </Slider>
            {propertyDetailInformationItem !== undefined &&
                <PropertyDetailInformationOverlay
                    show={showPropertyDetailInformationOverlay}
                    setShow={setShowPropertyDetailInformationOverlay}
                    property={propertyDetailInformationItem}
                />
            }
        </>
    );
};

interface PropertyCardProps {
    readonly property: Property;
    readonly setShowPropertyDetailInformationOverlay: (showPropertyDetailInformationOverlay: boolean) => void;
    readonly setPropertyDetailInformationItem: (property: Property) => void;
}

const PropertyCard = (props: PropertyCardProps): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const [isDetailsExpanded, setIsDetailsExpanded] = useState<boolean>(false);

    const [cardMediaContainerHeight, setCardMediaContainerHeight] = useState<number>();

    const showPropertyDetailInformation = (property: Property): void => {
        props.setPropertyDetailInformationItem(property);
        props.setShowPropertyDetailInformationOverlay(true);
    };

    const buildImageGetPath = (image: ImageEntity): string => {
        return PropertyService.buildImageApiPath(props.property, image);
    };

    return (
        <Card cardType="shadow" className="position-relative carousel-card m-3">
            <StatusIndicator active={props.property.active} right={-10} top={-10} />
            {cardMediaContainerHeight !== undefined &&
                <IconBadgeWrapper positionTop={cardMediaContainerHeight - 80} positionRight={-10}>
                    <MarketingStatusBadge marketingStatus={props.property.marketingStatus} />
                </IconBadgeWrapper>
            }
            <CardMedia setContainerHeight={setCardMediaContainerHeight}>
                <div className="d-flex align-items-center" style={{height: cardMediaContainerHeight + 'px', overflow: 'hidden'}}>
                    {(props.property.images.length > 0) ? (
                        <Image image={props.property.images[0]} buildImageGetPath={buildImageGetPath} className="w-100" />
                    ) : (
                        <img src={noImage} alt="Kein Bild" className="w-100" />
                    )}
                </div>
            </CardMedia>
            <CardContent className="px-2 px-md-4">
                <div className="card-slide-title d-flex justify-content-between align-items-start mb-3 position-relative">
                    <div className="fw-bolder">{props.property.title}</div>
                    <div className="text-nowrap ms-2">
                        <button className="btn btn-outline-secondary btn-sm me-2" onClick={(): void => showPropertyDetailInformation(props.property)}>
                            <i className="bi bi-eye-fill"></i>
                        </button>
                        <Link to={'/flaechen/' + props.property.id} className="btn btn-sm btn-secondary">
                            <i className="bi bi-pencil-square"></i>
                        </Link>
                    </div>
                </div>
                <div className="card-slide-main-details mb-4">
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Fläche-Nr.">
                                <BootstrapIcon iconName="shop-window" />
                            </Tooltip>
                        </div>
                        <div className="fw-bold">
                            #{props.property.id}
                            {props.property.internalDesignation !== null &&
                                <span className="ms-1">({props.property.internalDesignation})</span>
                            }
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Lage des Objekts">
                                <BootstrapIcon iconName="geo-alt-fill" />
                            </Tooltip>
                        </div>
                        <div>
                            {props.property.locationDetail.streetName !== null &&
                                <div>{props.property.locationDetail.streetName} {props.property.locationDetail.houseNumber}</div>
                            }
                            <div>{props.property.locationDetail.postalCode} {props.property.locationDetail.city!.placeName}</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Flächenangaben">
                                <BootstrapIcon iconName="bounding-box-circles" />
                            </Tooltip>
                        </div>
                        <div>
                            <div className="mb-1">
                                {new Intl.NumberFormat('de-DE').format(props.property.detail.areaSize)} m² (Verkaufs-)Fläche
                            </div>
                            {props.property.detail.secondaryAreaSize !== null &&
                                <div>
                                    {new Intl.NumberFormat('de-DE').format(props.property.detail.secondaryAreaSize)} m² Nebenfläche
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {/*
                        TODO: progress ring will be a feature after post release
                        <div className="card-slide-progress d-flex align-items-center gap-3 mb-4">
                            <ProgressRing stroke={7} radius={26} progress={88} />
                            <div>
                                <span className="d-block mb-1"><b>{88}%</b> vollständig</span>
                                <span className="d-block">Fülle alle Informationen aus für ein besseres Matching.</span>
                            </div>
                        </div>
                    */}
                <div className="card-action row mb-0">
                    <div className="col-3 col-md-6 text-center text-lg-end">
                        <button
                            type="button"
                            className="btn btn-default border-0 text-primary fs-2"
                            onClick={(): void => setIsDetailsExpanded((prev: boolean): boolean => prev === false)}
                        >
                            <i className={`bi ${isDetailsExpanded === true ? 'bi-chevron-down' : 'bi-chevron-right'}`}></i>
                        </button>
                    </div>
                    <div className="col-9 col-md-6">
                        {props.property.active === true &&
                            <button
                                className="btn btn-primary d-lg-block float-lg-end d-flex justify-content-center m-2 px-3 text-nowrap"
                                onClick={(): void => navigate('/flaechen/' + props.property.id + '/matching')}
                                type="button" aria-expanded="false"
                            >
                                <i className="bi bi-arrow-repeat"></i> Let's Match
                            </button>
                        }
                        {props.property.active === false &&
                            <button disabled={true} className="btn btn-secondary d-lg-block float-lg-end d-flex justify-content-center m-2 px-3 text-nowrap">
                                <i className="bi bi-arrow-repeat me-2"></i> Let's match
                            </button>
                        }
                    </div>
                </div>
            </CardContent>
            {isDetailsExpanded === true &&
                <div className={'p-4 card-shadow bg-light h-100 ' + propertySliderStyle.drawerBorderRadius}>
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Standorttyp und Lagekategorie">
                                <BootstrapIcon iconName="pin-map-fill" />
                            </Tooltip>
                        </div>
                        <div>
                            <div className="mb-1">
                                {getLocationTypeLabel(props.property.locationDetail.locationType)},
                            </div>
                            <div>
                                {getLocationCategoryLabel(props.property.locationDetail.locationCategory)}
                            </div>
                        </div>
                    </div>
                    {(props.property.detail.outdoorSalesAreaSize !== null && props.property.detail.outdoorSalesAreaSize !== 0) &&
                        <div className="d-flex justify-content-start mb-3">
                            <div className="me-3">
                                <Tooltip title="erweiterte Flächenangaben">
                                    <BootstrapIcon iconName="bounding-box" />
                                </Tooltip>
                            </div>
                            <div>
                                {new Intl.NumberFormat('de-DE').format(props.property.detail.outdoorSalesAreaSize)} m² Außenverkaufsfläche
                            </div>
                        </div>
                    }
                    <div className="d-flex justify-content-start mb-3">
                        <div className="me-3">
                            <Tooltip title="Kontaktperson(en)">
                                <BootstrapIcon iconName="person-lines-fill" />
                            </Tooltip>
                        </div>
                        <div>
                            {props.property.contacts.map((contact: Contact): string => contact.buildDisplayName()).join(' / ')}
                        </div>
                    </div>
                </div>
            }
        </Card>
    );
};

export default PropertySlider;
