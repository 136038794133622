import UserService from './UserService';
import Alert from '../Entity/Alert/Alert';
import AlertType from '../Entity/Alert/AlertType';
import UserPasswordChange from '../Entity/User/UserPasswordChange';
import FormData from '../../shared/Entity/Form/FormData';
import FieldValidationDefinition from '../FormValidationHandler/FieldValidationDefinition';
import RequiredValidationDefinition from '../FormValidationHandler/RequiredValidationDefinition';
import RepetitionValidationDefinition from '../FormValidationHandler/RepetitionValidationDefinition';
import PasswordValidationDefinition from '../FormValidationHandler/PasswordValidationDefinition';
import FormValidationHandler from '../FormValidationHandler/FormValidationHandler';
import InputField from '../Component/Form/Field/InputField';
import Spinner from '../../../components/Spinner';
import AlertBox from '../../../components/AlertBox';
import React, {ChangeEvent, useState} from 'react';

const successAlert: Alert = new Alert(AlertType.Success, 'Dein Passwort wurde erfolgreich geändert. Bitte nutze ab sofort Dein neues Passwort für das Login.');

const errorAlert: Alert = new Alert(AlertType.Error, 'Etwas ist schiefgelaufen. Bitte versuche es später noch einmal.');

const formErrorAlert: Alert = new Alert(AlertType.Error, 'Bitte fülle alle rot markierten Pflichtfelder aus.');

const fieldValidationDefinitions: FieldValidationDefinition<UserPasswordChange>[] = [
    new RequiredValidationDefinition<UserPasswordChange>('passwordOld', 'Passwort muss ausgefüllt sein.'),
    new RequiredValidationDefinition<UserPasswordChange>('password', 'Neues Passwort muss ausgefüllt sein.'),
    new PasswordValidationDefinition<UserPasswordChange>('password', 'Das Passwort-Format ist nicht korrekt'),
    new RepetitionValidationDefinition<UserPasswordChange>('passwordRepeat', 'password', 'Die eingegebenen Passwörter stimmen nicht überein.'),
];

const formValidationHandler: FormValidationHandler<UserPasswordChange> = new FormValidationHandler<UserPasswordChange>(fieldValidationDefinitions);

const userService: UserService = new UserService(process.env.REACT_APP_LLASM_API_URL!);

const UserPasswordChangeForm = (): React.JSX.Element => {
    const [userPasswordChange, setUserPasswordChange] = useState<UserPasswordChange>(new UserPasswordChange());

    const [formData, setFormData] = useState<FormData<UserPasswordChange>>({data: userPasswordChange!});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [alert, setAlert] = useState<Alert>();

    const persistUserPasswordChange = async (): Promise<void> => {
        formValidationHandler.validate(formData);

        if (formValidationHandler.hasErrors(formData) === true) {
            setAlert(formErrorAlert);

            return;
        }

        setIsLoading(true);

        try {
            await userService.persistUserPasswordChange(formData.data);

            resetForm();
            setAlert(successAlert);
        } catch (error: any) {
            setAlert(errorAlert);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (userPasswordChange as any)[event.target.name] = event.target.value;

        setUserPasswordChange(userPasswordChange);
        updateFormData();
        validateField(event.target.name);
    };

    const validateField = (fieldName: string): void => {
        formValidationHandler.validateField(fieldName, formData);

        setFormData({...formData, errors: formData.errors});
    };

    const updateFormData = (): void => {
        setFormData({...formData, data: userPasswordChange});
    };

    const resetForm = (): void => {
        userPasswordChange.passwordOld = '';
        userPasswordChange.password = '';
        userPasswordChange.passwordRepeat = '';

        setUserPasswordChange(userPasswordChange);
        updateFormData();
    };

    return (
        <>
            {isLoading === true &&
                <Spinner />
            }
            <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="passwordOld"
                        label="Passwort"
                        type="password"
                        required={true}
                        value={userPasswordChange.passwordOld}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'passwordOld')}
                    />
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="password"
                        label="Neues Passwort"
                        type="password"
                        required={true}
                        value={userPasswordChange.password}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'password')}
                    />
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <InputField
                        name="passwordRepeat"
                        label="Neues Passwort wiederholen"
                        type="password"
                        required={true}
                        value={userPasswordChange.passwordRepeat}
                        onChange={handleChange}
                        formErrors={FormValidationHandler.getFieldErrors(formData, 'passwordRepeat')}
                    />
                </div>
                <div className="col-xs-12 col-md-6 col-lg-3 mb-3">
                    <button
                        className="btn btn-primary w-100 mt-md-4"
                        type="submit"
                        onClick={persistUserPasswordChange}
                        disabled={formValidationHandler.hasErrors(formData) === true}
                    >
                        Passwort ändern
                    </button>
                </div>
            </div>
            {alert !== undefined &&
                <div className="mt-3">
                    <AlertBox alert={alert} autoDismiss={false} />
                </div>
            }
        </>
    );
}

export default UserPasswordChangeForm;
