import UserActivationType from './UserActivationType';
import UserActivationResponse from '../../../../api/Llasm/User/UserActivationResponse';

class UserActivation {
    private _activationUuid: string;

    private _expirationDate: string;

    private _userActivationType: UserActivationType;

    private _gtcAccepted: boolean | null = null;

    private _password: string;

    private _passwordRepeat: string;

    public static createFromUserActivationResponse(userActivationResponse: UserActivationResponse): UserActivation {
        const userActivation: UserActivation = new this();

        userActivation._activationUuid = userActivationResponse.activationUuid;
        userActivation._expirationDate = userActivationResponse.expirationDate;
        userActivation._userActivationType = userActivationResponse.userActivationType;

        return userActivation;
    }

    get activationUuid(): string {
        return this._activationUuid;
    }

    set activationUuid(value: string) {
        this._activationUuid = value;
    }

    get expirationDate(): string {
        return this._expirationDate;
    }

    set expirationDate(value: string) {
        this._expirationDate = value;
    }

    get gtcAccepted(): boolean | null {
        return this._gtcAccepted;
    }

    set gtcAccepted(value: boolean | null) {
        this._gtcAccepted = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get passwordRepeat(): string {
        return this._passwordRepeat;
    }

    set passwordRepeat(value: string) {
        this._passwordRepeat = value;
    }

    get userActivationType(): UserActivationType {
        return this._userActivationType;
    }

    set userActivationType(value: UserActivationType) {
        this._userActivationType = value;
    }
}

export default UserActivation