import ConversationType from './ConversationType';
import ParticipatingUser from './ParticipatingUser';
import ReferenceType from './ReferenceType';

abstract class Conversation {
    private readonly _conversationType: ConversationType;

    protected _id?: number;

    protected _unreadMessages: boolean;

    protected _createdAt: Date;

    protected _lastMessageAt: Date | null = null;

    protected _firstReferenceFrom: ParticipatingUser;

    protected _secondReferenceFrom: ParticipatingUser;

    protected _firstReference: string;

    protected _secondReference: string;

    protected _firstReferenceType: ReferenceType;

    protected _secondReferenceType: ReferenceType;

    protected constructor(conversationType: ConversationType) {
        this._conversationType = conversationType;
    }

    abstract buildIdentifier(): string;

    get conversationType(): ConversationType {
        return this._conversationType;
    }

    get id(): number | undefined {
        return this._id;
    }

    set id(value: number | undefined) {
        this._id = value;
    }

    get unreadMessages(): boolean {
        return this._unreadMessages;
    }

    set unreadMessages(value: boolean) {
        this._unreadMessages = value;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    set createdAt(value: Date) {
        this._createdAt = value;
    }

    get lastMessageAt(): Date | null {
        return this._lastMessageAt;
    }

    set lastMessageAt(value: Date | null) {
        this._lastMessageAt = value;
    }

    get firstReferenceFrom(): ParticipatingUser {
        return this._firstReferenceFrom;
    }

    set firstReferenceFrom(value: ParticipatingUser) {
        this._firstReferenceFrom = value;
    }

    get secondReferenceFrom(): ParticipatingUser {
        return this._secondReferenceFrom;
    }

    set secondReferenceFrom(value: ParticipatingUser) {
        this._secondReferenceFrom = value;
    }

    get firstReference(): string {
        return this._firstReference;
    }

    set firstReference(value: string) {
        this._firstReference = value;
    }

    get secondReference(): string {
        return this._secondReference;
    }

    set secondReference(value: string) {
        this._secondReference = value;
    }

    get firstReferenceType(): ReferenceType {
        return this._firstReferenceType;
    }

    set firstReferenceType(value: ReferenceType) {
        this._firstReferenceType = value;
    }

    get secondReferenceType(): ReferenceType {
        return this._secondReferenceType;
    }

    set secondReferenceType(value: ReferenceType) {
        this._secondReferenceType = value;
    }

    protected updateFromConversation(conversation: Conversation): void {
        this._unreadMessages = conversation.unreadMessages;
        this._createdAt = conversation.createdAt;
        this._lastMessageAt = conversation.lastMessageAt;
        this._firstReferenceFrom = conversation.firstReferenceFrom;
        this._secondReferenceFrom = conversation.secondReferenceFrom;
        this._firstReference = conversation.firstReference;
        this._secondReference = conversation.secondReference;
        this._firstReferenceType = conversation.firstReferenceType;
        this._secondReferenceType = conversation.secondReferenceType;
    }
}

export default Conversation;
