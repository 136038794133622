class UserEmailChange {
    private _newEmail: string;

    private _repeatEmail: string;

    get newEmail(): string {
        return this._newEmail;
    }

    set newEmail(value: string) {
        this._newEmail = value;
    }

    get repeatEmail(): string {
        return this._repeatEmail;
    }

    set repeatEmail(value: string) {
        this._repeatEmail = value;
    }
}

export default UserEmailChange;
